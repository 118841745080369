import { stringEnumValidator } from '../../packages/validator/validator.js';

/**
 * Type of the account.
 */
export type AccountType = 'liability' | 'income' | 'equity' | 'asset' | 'expense' | 'cash' | 'bank';

/**
 * Returns whether the given account type is income account.
 */
export const isIncomeAccountType = (type: AccountType) => type === 'income';

/**
 * Returns whether the given account type is expense account.
 */
export const isExpenseAccountType = (type: AccountType) => type === 'expense';

/**
 * Returns whether the given account type is asset account.
 */
export const isAssetAccountType = (type: AccountType) =>
  type === 'asset' || type === 'cash' || type === 'bank';

/**
 * Returns whether the given account type is liability account.
 */
export const isLiabilityAccountType = (type: AccountType) => type === 'liability';

/**
 * Returns whether the given account type is cash account.
 */
export const isCashAccountType = (type: AccountType) => type === 'cash';

/**
 * Validates AccountType value.
 */
export const validatorOfAccountType = stringEnumValidator<AccountType>([
  'liability',
  'income',
  'equity',
  'asset',
  'expense',
  'cash',
  'bank'
]);
