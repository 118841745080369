import React from 'react';
import { findAccount } from '../../../../organizer-datamodel/accounting/Chart.js';
import { Link } from '../../../../packages/router/Link.js';

type Props = {
  accountId: string;
};

/**
 * Helper component to render an account link.
 */
export const AccountLink = ({ accountId }: Props) => {
  const account = findAccount(accountId);

  return (
    <Link to={`/account/${accountId}`}>
      {account.code} ({account.name})
    </Link>
  );
};
