import React, { useCallback } from 'react';
import { StoredComment } from '../../organizer-datamodel/comments/StoredComment.js';
import { formatUnixTimestampDate } from '../../packages/date/dateFunctions.js';
import { Link } from '../../packages/router/Link.js';
import { euc } from '../../packages/url/encodeUriComponents.js';
import { Buttons } from '../../packages/ui/form/Buttons.js';
import { Button } from '../../packages/ui/button/Button.js';

type Props = {
  comment: StoredComment;
  onRemove: (commentId: string) => void;
};

/**
 * Renders one row in the post comments table.
 */
export const CommentRow = ({ comment, onRemove }: Props) => {
  const handleRemove = useCallback(() => {
    if (confirm('Remove comment?')) {
      onRemove(comment.id);
    }
  }, [comment, onRemove]);

  return (
    <tr>
      <td>{comment.author}</td>
      <td>{formatUnixTimestampDate(comment.timestamp)}</td>
      <td>{comment.content.substring(0, 200)}</td>
      <td>
        <Buttons>
          <Button size="small" onClick={handleRemove}>
            Remove
          </Button>
          <Link to={euc`/comment/${comment.id}`} className="button small print-none">
            Edit
          </Link>
        </Buttons>
      </td>
    </tr>
  );
};
