import React from 'react';
import { FontSize } from '../../FontSize.js';
import { Column } from './Column.js';
import { Table } from '../Table.js';

type Props<R = any> = {
  fontSize?: FontSize;
  columns: Column<R>[];
  rows: R[];
};

/**
 * Fixed-layout table.
 */
export const FixedTable = ({ fontSize, columns, rows }: Props) => {
  return (
    <Table fontSize={fontSize} fullWidth={true} tableLayout="fixed">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index} style={{ textAlign: column.textAlign }} colSpan={column.span ?? 1}>
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex} style={{ textAlign: column.textAlign }} colSpan={column.span ?? 1}>
                {column.render(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
