import React from 'react';
import { LogoutLink } from './LogoutLink.js';
import { Link } from '../../../packages/router/Link.js';

/**
 * Renders the top menu.
 */
export const TopMenu = () => {
  return (
    <>
      &nbsp;<Link to="/entries">Entries</Link>
      &nbsp;<Link to="/accounts">Accounts</Link>
      &nbsp;<Link to="/comments">Comments</Link>
      &nbsp;<Link to="/analytics">Analytics</Link>
      &nbsp;
      <LogoutLink />
    </>
  );
};
