import React from 'react';
import { FilesTable } from './FilesTable.js';
import { useEntryView } from './ViewProvider.js';
import { Form } from '../../../../../packages/ui/form/Form.js';
import { FormRow } from '../../../../../packages/ui/form/FormRow.js';
import { Alert } from '../../../../../packages/ui/Alert.js';
import { FilesInput } from '../../../../../packages/ui/form/FilesInput.js';

/**
 * Renders the block of files under the entry form.
 */
export const Files = () => {
  const { files, uploadFiles, isUploading, uploadError } = useEntryView();

  return (
    <>
      {files.length > 0 && <FilesTable />}
      <Form>
        {files.length === 0 && (
          <FormRow columns={1}>
            <Alert>The entry has no files.</Alert>
          </FormRow>
        )}
        {isUploading && (
          <FormRow columns={1}>
            <Alert>Files are being uploaded ...</Alert>
          </FormRow>
        )}
        <FormRow columns={1}>
          <FilesInput
            label="Upload files"
            accept=".png,.jpg,.jpeg,.pdf,.odf,.doc,.docx,.zip"
            onChange={uploadFiles}
          />
        </FormRow>
      </Form>
      {uploadError && <Alert type="error">{uploadError}</Alert>}
    </>
  );
};
