/**
 * Returns swap pair to move item with given index upwards.
 */
const upPair = (i: number, length: number): [number, number] => {
  if (i <= 0) {
    return [i, length - 1];
  } else if (i <= length - 1) {
    return [i, i - 1];
  } else {
    return [i, i];
  }
};

/**
 * Returns swap pair to move item with given index downwards.
 */
const downPair = (i: number, length: number): [number, number] => {
  if (i >= length - 1) {
    return [i, 0];
  } else if (i >= 0) {
    return [i, i + 1];
  } else {
    return [i, i];
  }
};

/**
 * Swaps items at given array indices. Returns copy of the array.
 */
const swap = <T>(array: T[], i: number, j: number): T[] => {
  const copy = [...array];
  const tmp = copy[i];
  copy[i] = copy[j];
  copy[j] = tmp;
  return copy;
};

/**
 * Moves item in the given direction in the array.
 */
export const moveItem = <T>(array: T[], index: number, direction: 'up' | 'down'): T[] => {
  const [i, j] = direction === 'up' ? upPair(index, array.length) : downPair(index, array.length);
  return swap(array, i, j);
};
