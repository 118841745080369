import { Credentials } from '../../organizer-datamodel/auth/Credentials.js';
import { requestJSON } from '../../packages/fetcher/fetcherFunctions.js';

/**
 * Runs the login request.
 */
export const login = async (username: string, password: string) => {
  await requestJSON<void, Credentials>('/api/auth/login', 'POST', { username, password });
};

/**
 * Runs the logout request.
 */
export const logout = async () => {
  await requestJSON<void, undefined>('/api/auth/logout', 'POST', undefined);
};
