import { Modify } from '../Modify.js';
import { Update } from '../Update.js';
import { FunctionCache } from '../cache.js';
import { createModifyProperty } from './createModifyProperty.js';

const cache = new FunctionCache();

/**
 * Creates "update" function for the given object property.
 * Returns stable function identity.
 */
export const createUpdateProperty = <T extends object, K extends keyof T>(
  update: Update<T>,
  key: K
): Update<T[K]> =>
  cache.cached(update, key, () => (op: Modify<T[K]>) => update(createModifyProperty(op, key)));
