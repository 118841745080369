import React, { ReactNode } from 'react';
import { Table } from '../../packages/ui/table/Table.js';

/**
 * Common table component.
 */
export const AccountingTable = ({ children }: { children: ReactNode }) => {
  return (
    <Table fullWidth={true} fontSize="tiny">
      {children}
    </Table>
  );
};
