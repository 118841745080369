/**
 * Formats monetary amount (given as cents).
 */
export const formatAmount = (value: number) => {
  const neg = value < 0;
  if (neg) {
    value = -value;
  }
  const integerPart = Math.floor(value / 100);
  const fractionalPart = value % 100;
  return (neg ? '-' : '') + integerPart + '.' + ((fractionalPart < 10 ? '0' : '') + fractionalPart);
};
