import React from 'react';
import { Link } from '../../../../../packages/router/Link.js';
import { euc } from '../../../../../packages/url/encodeUriComponents.js';

type Props = {
  entryId: string;
};

/**
 * Renders the entry copy link.
 */
export const CopyLink = ({ entryId }: Props) => {
  return (
    <Link to={euc`/copy/${entryId}`} className="button print-none">
      Copy
    </Link>
  );
};
