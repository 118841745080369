import React, { ReactNode } from 'react';
import { Link } from '../../../../../packages/router/Link.js';
import { euc } from '../../../../../packages/url/encodeUriComponents.js';

type Props = {
  entryId: string;
  children: ReactNode;
};

/**
 * Renders entry link.
 */
export const EntryLink = ({ entryId, children }: Props) => {
  return <Link to={euc`/entry/${entryId}`}>{children}</Link>;
};
