import { useCallback, useEffect, useState } from 'react';
import { getComment } from '../api/comments.js';
import { Layout } from '../accounting/layout/Layout.js';
import React from 'react';
import { CommentUpdateData } from '../../packages/comments/CommentUpdateData.js';
import { requestPutJson } from '../../packages/fetcher/fetcherFunctions.js';
import { useForm } from '../../packages/form/useForm.js';
import { createWriteProperty } from '../../packages/optics/optics/property/createWriteProperty.js';
import { useRequiredParameter } from '../../packages/router/params/useRequiredParameter.js';
import { useHref } from '../../packages/router/href/HrefProvider.js';
import { euc } from '../../packages/url/encodeUriComponents.js';
import { Form } from '../../packages/ui/form/Form.js';
import { FormRow } from '../../packages/ui/form/FormRow.js';
import { Alert } from '../../packages/ui/Alert.js';
import { Input } from '../../packages/ui/form/Input.js';
import { Textarea } from '../../packages/ui/form/Textarea.js';
import { Buttons } from '../../packages/ui/form/Buttons.js';
import { Button } from '../../packages/ui/button/Button.js';

type Values = {
  author: string;
  comment: string;
};

const initialValues: Values = {
  author: '',
  comment: ''
};

type Errors = {
  author?: string;
  comment?: string;
  generic?: string;
};

/**
 * Renders comment edit page.
 */
export const CommentPage = () => {
  const [postId, setPostId] = useState<string>();
  const commentId = useRequiredParameter('commentId');
  const { setHref } = useHref();

  const validate = useCallback(async (values: Values) => {
    return validateInputs(values);
  }, []);

  const submit = useCallback(
    async (values: Values) => {
      const url = euc`/api/comment/${commentId}`;
      await requestPutJson<string, CommentUpdateData>(url, {
        author: values.author,
        content: values.comment
      });
      if (postId) {
        setHref(euc`/comments/${postId}`);
      }
    },
    [commentId, postId, setHref]
  );

  const handleSubmissionError = useCallback((err: unknown): Errors => {
    return { generic: err + '' };
  }, []);

  const { handleSubmit, values, setValues, errors } = useForm<Values, Errors>({
    initialValues,
    initialErrors: {},
    submit,
    validate,
    handleSubmissionError
  });

  useEffect(() => {
    (async () => {
      const comment = await getComment(commentId);
      setValues(() => ({
        author: comment.author,
        comment: comment.content
      }));
      setPostId(comment.postId);
    })();
  }, [setValues, commentId]);

  const handleAuthorChange = createWriteProperty(setValues, 'author');
  const handleCommentChange = createWriteProperty(setValues, 'comment');

  return (
    <Layout title="Blog comments">
      <h2>Update comment</h2>
      <Form onSubmit={handleSubmit}>
        {errors.generic && (
          <FormRow columns={1}>
            <Alert type="error">{errors.generic}</Alert>
          </FormRow>
        )}
        <FormRow columns={1}>
          <Input label="Author" value={values.author} onChange={handleAuthorChange} error={errors.author} />
        </FormRow>
        <FormRow columns={1}>
          <small>
            You can use links inside the comment text. Make sure there are no spaces inside the link and the
            link is shorter than 110 characters. The links will <strong>not</strong> be rendered clickable.
            You can use empty lines to break the text into paragraphs. Any other type of formatting is not
            supported.
          </small>
        </FormRow>
        <FormRow columns={1}>
          <Textarea
            label="Comment"
            value={values.comment}
            onChange={handleCommentChange}
            error={errors.comment}
            rows={5}
          />
        </FormRow>
        <FormRow columns={1}>
          <Buttons>
            <Button type="submit">Post</Button>
          </Buttons>
        </FormRow>
      </Form>
    </Layout>
  );
};

/**
 * Validates the comment form inputs.
 */
const validateInputs = (values: Values): Errors => {
  const errors: Errors = {};
  if (values.author === '') {
    errors.author = 'Please enter your name.';
  }
  if (values.comment === '') {
    errors.comment = 'Please enter your comment.';
  }
  return errors;
};
