import { createComposedValidator, stringValidator } from '../../packages/validator/validator.js';
import { Account } from './Account.js';

/**
 * Kassa
 */
export const Ass_01 = '5c422a0a-21f4-4d66-ab05-a256897e781a';

/**
 * SEB arvelduskonto
 */
export const Ass_02 = 'b036de1f-ddb4-4c26-a5b3-623ff9b88f7c';

/**
 * LHV arvelduskonto
 */
export const Ass_03 = '355bac91-8098-4caa-a703-42a4a13868ed';

/**
 * Swedbanki arvelduskonto
 */
export const Ass_04 = '5fc615f6-f5c3-48af-aed1-8efef2327ce4';

/**
 * Ettemaksed tarnijatele
 */
export const Ass_05 = 'dacba750-5fe6-4c68-ae62-e42c07e5eeef';

/**
 * Laekumata arved
 */
export const Ass_06 = 'e18d8be2-d026-4196-a484-123c2bb1360a';

/**
 * PayPal konto, USD
 */
export const Ass_07 = '41c5c716-5230-4244-ae5e-d427b754d1e3';

/**
 * PayPal konto, EUR
 */
export const Ass_08 = '53d9ae62-a1a2-4664-a051-d629fe1c2b92';

/**
 * Põhivara
 */
export const Ass_09 = '8d48e053-193c-458d-a5bf-a40994be67fd';

/**
 * Maksude ettemaksed
 */
export const Ass_10 = '2aafc41c-9084-4a22-a90b-fa9897a10f46';

/**
 * Põhivara akumuleeritud kulum
 */
export const Ass_11 = 'e94a9b7c-d600-4e35-a6af-79710dc94477';

/**
 * Osakapital
 */
export const Eqt_01 = '52609791-f314-4666-a63f-8c24ca9006d2';

/**
 * Reservkapital
 */
export const Eqt_02 = '30f6d459-4c80-4ba3-a6c3-70a3a5a2bd9f';

/**
 * Eelmiste perioodide kasum
 */
export const Eqt_03 = '0361a7a9-3595-4d16-a314-baed1e7885b9';

/**
 * Üldised kulud
 */
export const Exp_01 = '4332dd24-b20d-4f6e-a148-64d7db04e119';

/**
 * Konvertimiskulu
 */
export const Exp_02 = '574f6fda-cd6e-449d-aaa2-c86d04ce7254';

/**
 * Palgakulu
 */
export const Exp_03 = 'd2eaefd9-e79c-42d6-aca9-ceb44b15e762';

/**
 * Tulevaste perioodide kulud
 */
export const Exp_04 = 'd82dfe5d-d327-457d-a503-d7d44e9179da';

/**
 * Dividendi tulumaksu kulu
 */
export const Exp_05 = 'c2b63f04-c804-4f74-abeb-34de4ccaa0f1';

/**
 * Sotsiaalmaksu kulu
 */
export const Exp_06 = 'b73d6f41-d086-47e9-ad11-4ebce372c1f0';

/**
 * Amortisatsioonikulu
 */
export const Exp_07 = 'fad2d23b-d723-40ae-a668-fe029665aa97';

/**
 * Üldised kulud (EU arved)
 */
export const Exp_08 = 'dab892ee-78be-4bdf-9321-4266b7eb18d8';

/**
 * Üldised kulud (EU-st väljast arved)
 */
export const Exp_09 = 'd5a63046-9936-4831-ae01-9d6b55780296';

/**
 * Müük käibemaksuta
 */
export const Inc_01 = '4d320fd8-7348-476d-a9c6-67e3b29a6f14';

/**
 * Müük käibemaksuga
 */
export const Inc_02 = '12cd2968-56b3-4262-ac5f-923e4ec960a9';

/**
 * Arvelduskrediit tarnijatelt
 */
export const Inc_03 = '70274333-57c8-48fc-ac76-f953923daac6';

/**
 * Konverteerimistulu
 */
export const Inc_04 = 'aa699939-742d-43c1-a6b4-094376ca09fc';

/**
 * Arvelduskontode intressitulu
 */
export const Inc_05 = '1d36ad39-1dc7-4c21-a909-532982e014d9';

/**
 * Muud tulud
 */
export const Inc_06 = '028cfd5d-e06f-4ab4-aac1-9378209ac5a4';

/**
 * Müük Euroopa Liitu
 */
export const Inc_07 = 'abc23393-86e0-4329-9b60-dac900507ff7';

/**
 * Müük väljapoole Euroopa Liitu
 */
export const Inc_08 = '74382e64-156c-4424-b1ad-f8741d18396f';

/**
 * Maksmata arved
 */
export const Lia_01 = '076a3de0-d5cb-454c-aa9b-fad22e0086ae';

/**
 * Käibemaks
 */
export const Lia_02 = '7ab3a21c-150d-412a-abb7-aa5b59aacd5e';

/**
 * Dividendikohustus
 */
export const Lia_03 = '6d5068c4-3fff-4fc2-ac62-d9c5ba313369';

/**
 * Dividendi tulumaks
 */
export const Lia_04 = 'dbafaeda-276f-4f0b-a960-95091b2306ed';

/**
 * Sotsiaalmaks
 */
export const Lia_05 = 'a64f139f-3efc-4d27-a97e-e4738c6eea92';

/**
 * Netopalk
 */
export const Lia_06 = '9e5d7238-8d6b-4a97-a0ae-8da49c8b11ee';

/**
 * Muud võlgevused
 */
export const Lia_07 = 'a2691397-038f-4333-ae62-4f8c23c8bfaa';

/**
 * Tööandja töötuskindlustus
 */
export const Lia_08 = '440ea61d-5570-42e3-a4f6-6f6b00cae06b';

/**
 * Kinnipeetud kogumispension
 */
export const Lia_09 = 'a4709e3d-0671-40be-a108-a94dabdff4a3';

/**
 * Kinnipeetud töötuskindlustus
 */
export const Lia_10 = '0f178a4c-2d37-4974-a9e3-d935d2e3f3f8';

/**
 * Kinnipeetud tulumaks
 */
export const Lia_11 = '8aab72ea-d144-4d01-a830-601094103ee9';

/**
 * Maksuvõlg
 */
export const Lia_12 = 'c734418d-59a5-4839-8b4c-cb9e6c897d74';

/**
 * Hardcoded list of accounts.
 */
export const accounts: Account[] = [
  {
    id: Ass_01,
    name: 'Kassa',
    code: 'Ass-01',
    type: 'cash'
  },
  {
    id: Ass_02,
    name: 'SEB arvelduskonto',
    code: 'Ass-02',
    type: 'bank'
  },
  {
    id: Ass_03,
    name: 'LHV arvelduskonto',
    code: 'Ass-03',
    type: 'bank'
  },
  {
    id: Ass_04,
    name: 'Swedbanki arvelduskonto',
    code: 'Ass-04',
    type: 'bank'
  },
  {
    id: Ass_05,
    name: 'Ettemaksed tarnijatele',
    code: 'Ass-05',
    type: 'asset'
  },
  {
    id: Ass_06,
    name: 'Laekumata arved',
    code: 'Ass-06',
    type: 'asset'
  },
  {
    id: Ass_07,
    name: 'PayPal konto, USD',
    code: 'Ass-07',
    type: 'bank'
  },
  {
    id: Ass_08,
    name: 'PayPal konto, EUR',
    code: 'Ass-08',
    type: 'bank'
  },
  {
    id: Ass_09,
    name: 'Põhivara',
    code: 'Ass-09',
    type: 'asset'
  },
  {
    id: Ass_10,
    name: 'Maksude ettemaksed',
    code: 'Ass-10',
    type: 'asset'
  },
  {
    id: Ass_11,
    name: 'Põhivara akumuleeritud kulum',
    code: 'Ass-11',
    type: 'asset'
  },
  {
    id: Eqt_01,
    name: 'Osakapital',
    code: 'Eqt-01',
    type: 'equity'
  },
  {
    id: Eqt_02,
    name: 'Reservkapital',
    code: 'Eqt-02',
    type: 'equity'
  },
  {
    id: Eqt_03,
    name: 'Eelmiste perioodide kasum',
    code: 'Eqt-03',
    type: 'equity'
  },
  {
    id: Exp_01,
    name: 'Üldised kulud',
    code: 'Exp-01',
    type: 'expense'
  },
  {
    id: Exp_02,
    name: 'Konvertimiskulu',
    code: 'Exp-02',
    type: 'expense'
  },
  {
    id: Exp_03,
    name: 'Palgakulu',
    code: 'Exp-03',
    type: 'expense'
  },
  {
    id: Exp_04,
    name: 'Tulevaste perioodide kulud',
    code: 'Exp-04',
    type: 'expense'
  },
  {
    id: Exp_05,
    name: 'Dividendi tulumaksu kulu',
    code: 'Exp-05',
    type: 'expense'
  },
  {
    id: Exp_06,
    name: 'Sotsiaalmaksu kulu',
    code: 'Exp-06',
    type: 'expense'
  },
  {
    id: Exp_07,
    name: 'Amortisatsioonikulu',
    code: 'Exp-07',
    type: 'expense'
  },
  {
    id: Exp_08,
    name: 'Üldised kulud (EU arved)',
    code: 'Exp-08',
    type: 'expense'
  },
  {
    id: Exp_09,
    name: 'Üldised kulud (EU-st väljast arved)',
    code: 'Exp-09',
    type: 'expense'
  },
  {
    id: Inc_01,
    name: 'Müük käibemaksuta',
    code: 'Inc-01',
    type: 'income'
  },
  {
    id: Inc_02,
    name: 'Müük käibemaksuga',
    code: 'Inc-02',
    type: 'income'
  },
  {
    id: Inc_03,
    name: 'Arvelduskrediit tarnijatelt',
    code: 'Inc-03',
    type: 'income'
  },
  {
    id: Inc_04,
    name: 'Konverteerimistulu',
    code: 'Inc-04',
    type: 'income'
  },
  {
    id: Inc_05,
    name: 'Arvelduskontode intressitulu',
    code: 'Inc-05',
    type: 'income'
  },
  {
    id: Inc_06,
    name: 'Muud tulud',
    code: 'Inc-06',
    type: 'income'
  },
  {
    id: Inc_07,
    name: 'Müük käibemaksuta (EU)',
    code: 'Inc-07',
    type: 'income'
  },
  {
    id: Inc_08,
    name: 'Müük käibemaksuta (EU-st välja)',
    code: 'Inc-08',
    type: 'income'
  },
  {
    id: Lia_01,
    name: 'Maksmata arved',
    code: 'Lia-01',
    type: 'liability'
  },
  {
    id: Lia_02,
    name: 'Käibemaks',
    code: 'Lia-02',
    type: 'liability'
  },
  {
    id: Lia_03,
    name: 'Dividendikohustus',
    code: 'Lia-03',
    type: 'liability'
  },
  {
    id: Lia_04,
    name: 'Dividendi tulumaks',
    code: 'Lia-04',
    type: 'liability'
  },
  {
    id: Lia_05,
    name: 'Sotsiaalmaks',
    code: 'Lia-05',
    type: 'liability'
  },
  {
    id: Lia_06,
    name: 'Netopalk',
    code: 'Lia-06',
    type: 'liability'
  },
  {
    id: Lia_07,
    name: 'Muud võlgevused',
    code: 'Lia-07',
    type: 'liability'
  },
  {
    id: Lia_08,
    name: 'Tööandja töötuskindlustus',
    code: 'Lia-08',
    type: 'liability'
  },
  {
    id: Lia_09,
    name: 'Kinnipeetud kogumispension',
    code: 'Lia-09',
    type: 'liability'
  },
  {
    id: Lia_10,
    name: 'Kinnipeetud töötuskindlustus',
    code: 'Lia-10',
    type: 'liability'
  },
  {
    id: Lia_11,
    name: 'Kinnipeetud tulumaks',
    code: 'Lia-11',
    type: 'liability'
  },
  {
    id: Lia_12,
    name: 'Maksuvõlg',
    code: 'Lia-12',
    type: 'liability'
  }
];

const idToAccount = new Map<string, Account>();
const codeToAccount = new Map<string, Account>();

for (const account of accounts) {
  const { id, code } = account;
  if (idToAccount.has(id)) {
    throw new Error(`Duplicate account id: ${id}.`);
  }
  idToAccount.set(id, account);
  if (codeToAccount.has(code)) {
    throw new Error(`Duplicate account code: ${code}.`);
  }
  codeToAccount.set(code, account);
}

/**
 * Finds account with the given id. Throws an error
 * when the account cannot be found.
 */
export const findAccount = (accountId: string) => {
  const account = idToAccount.get(accountId);
  if (!account) {
    throw new Error(`No account ${accountId}.`);
  }
  return account;
};

/**
 * Checks whether the given account exists.
 */
export const hasAccount = (accountId: string) => idToAccount.has(accountId);

export const validatorOfAccountId = createComposedValidator<string>(stringValidator, (value: unknown) => {
  if (!idToAccount.has(value as string)) {
    throw new Error(`There is no account with ID ${value}.`);
  }
  return value;
});
