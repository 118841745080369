import { Modify } from '../Modify.js';
import { Update } from '../Update.js';
import { FunctionCache } from '../cache.js';
import { createModifyIndex } from './createModifyIndex.js';

const cache = new FunctionCache();

/**
 * Creates "update" function for the given index in the array.
 * Returns stable function identity.
 */
export const createUpdateIndex = <T>(update: Update<T[]>, index: number): Update<T> =>
  cache.cached(update, index, () => (op: Modify<T>) => update(createModifyIndex(op, index)));
