import React, { useCallback } from 'react';
import { AccountSelector } from './AccountSelector.js';
import { CurrencySelector } from './CurrencySelector.js';
import { ItemErrors } from '../entry/EntryErrors.js';
import { useFilter } from '../../../../provider/FilterProvider.js';
import {
  LinkMap,
  isAmountLinkedTo,
  isDateLinkedTo,
  removeAmountLink,
  removeDateLink
} from '../entry/LinkMap.js';
import { ItemValues } from '../../../../../../organizer-datamodel/accounting/form/ItemValues.js';
import { Update } from '../../../../../../packages/optics/optics/Update.js';
import { createWriteProperty } from '../../../../../../packages/optics/optics/property/createWriteProperty.js';
import { createUpdateIndex } from '../../../../../../packages/optics/optics/index/createUpdateIndex.js';
import { FormRow } from '../../../../../../packages/ui/form/FormRow.js';
import { Input } from '../../../../../../packages/ui/form/Input.js';
import { Buttons } from '../../../../../../packages/ui/form/Buttons.js';
import { Button } from '../../../../../../packages/ui/button/Button.js';

type Props = {
  index: number;
  numOfItems: number;
  errors: ItemErrors;
  values: ItemValues;
  onRemove: () => void;
  onUpdateItems: Update<ItemValues[]>;
  onUp: () => void;
  onDown: () => void;
  linkMap: LinkMap;
  onUpdateLinkMap: (linkMap: LinkMap) => void;
};

/**
 * Helper component to render entry item inputs.
 */
export const ItemInputs = ({
  index,
  numOfItems,
  errors,
  values,
  onRemove,
  onUpdateItems,
  onUp,
  onDown,
  linkMap,
  onUpdateLinkMap
}: Props) => {
  const { year } = useFilter();
  const onUpdate = createUpdateIndex(onUpdateItems, index);

  const isDateLinked = isDateLinkedTo(values.id, linkMap);
  const isAmountLinked = isAmountLinkedTo(values.id, linkMap);

  // TODO: reduce duplicated code

  const handleAmountChange = useCallback(
    (value: string) => {
      onUpdateItems((items: ItemValues[]) => {
        // Syncs next linked amount values.
        const linkedId = linkMap.amounts[values.id];
        return items.map((item) => {
          if (item.id === values.id || item.id === linkedId) {
            if (item.currency === 'eur') {
              // Update both EUR and non-EUR amount in sync.
              return { ...item, amount: value, eurAmount: value };
            } else {
              return { ...item, amount: value };
            }
          } else {
            return item;
          }
        });
      });
      if (isAmountLinked) {
        // Removes link if was linked to a previous item.
        onUpdateLinkMap(removeAmountLink(values.id, linkMap));
      }
    },
    [isAmountLinked, linkMap, onUpdateItems, onUpdateLinkMap, values]
  );

  const handleEurAmountChange = useCallback(
    (value: string) => {
      onUpdateItems((items: ItemValues[]) => {
        // Syncs next linked amount values.
        const linkedId = linkMap.amounts[values.id];
        return items.map((item) => {
          if (item.id === values.id || item.id === linkedId) {
            if (item.currency === 'eur') {
              // Update both EUR and non-EUR amount in sync.
              return { ...item, amount: value, eurAmount: value };
            } else {
              return { ...item, eurAmount: value };
            }
          } else {
            return item;
          }
        });
      });
      if (isAmountLinked) {
        // Removes link if was linked to a previous item.
        onUpdateLinkMap(removeAmountLink(values.id, linkMap));
      }
    },
    [onUpdateItems, linkMap, values, onUpdateLinkMap, isAmountLinked]
  );

  const handleDateChange = useCallback(
    (date: string) => {
      onUpdateItems((items: ItemValues[]) => {
        // Syncs next linked date entries.
        const linkedId = linkMap.dates[values.id];
        return items.map((item) => {
          return item.id === values.id || item.id === linkedId ? { ...item, date } : item;
        });
      });
      if (isDateLinked) {
        // Removes link if was linked to a previous item.
        onUpdateLinkMap(removeDateLink(values.id, linkMap));
      }
    },
    [linkMap, values, onUpdateItems, onUpdateLinkMap, isDateLinked]
  );

  const handleTitleChange = createWriteProperty(onUpdate, 'title');
  const handleDebitChange = createWriteProperty(onUpdate, 'debit');
  const handleCreditChange = createWriteProperty(onUpdate, 'credit');
  const handleCurrencyChange = createWriteProperty(onUpdate, 'currency');

  const minDate = `${year}-01-01`;
  const maxDate = `${year}-12-31`;

  return (
    <>
      <FormRow columns={1}>
        <Input
          label="Item title"
          value={values.title}
          onChange={handleTitleChange}
          placeholder="Title"
          error={errors.title}
        />
      </FormRow>
      <FormRow columns={3}>
        <Input
          type="date"
          label={isDateLinked ? 'Date (linked)' : 'Date'}
          value={values.date}
          onChange={handleDateChange}
          placeholder="Date"
          error={errors.date}
          min={minDate}
          max={maxDate}
        />
        <AccountSelector
          type="debit"
          value={values.debit}
          onChange={handleDebitChange}
          error={errors.debit}
        />
        <AccountSelector
          type="credit"
          value={values.credit}
          onChange={handleCreditChange}
          error={errors.credit}
        />
      </FormRow>
      <FormRow columns={3}>
        <CurrencySelector value={values.currency} onChange={handleCurrencyChange} error={errors.currency} />
        <Input
          label={isAmountLinked ? 'Original amount (linked)' : 'Original amount'}
          value={values.amount}
          onChange={handleAmountChange}
          placeholder="0.00"
          error={errors.amount}
        />
        <Input
          label={isAmountLinked ? 'EUR amount (linked)' : 'EUR amount'}
          value={values.eurAmount}
          onChange={handleEurAmountChange}
          placeholder="0.00"
          error={errors.eurAmount}
        />
      </FormRow>
      <FormRow columns={1}>
        <Buttons>
          <Button size="small" onClick={onRemove}>
            Delete item
          </Button>
          {index > 0 && (
            <Button size="small" onClick={onUp}>
              ⇧
            </Button>
          )}
          {index < numOfItems - 1 && (
            <Button size="small" onClick={onDown}>
              ⇩
            </Button>
          )}
        </Buttons>
      </FormRow>
    </>
  );
};
