/**
 * Helper to cache the given function.
 */
export const createCachedFunction = <A extends object, R>(fn: (value: A) => R): ((value: A) => R) => {
  const cache = new WeakMap<A, R>();
  return (value: A) => {
    if (cache.has(value)) {
      return cache.get(value) as R;
    } else {
      const result = fn(value);
      cache.set(value, result);
      return result;
    }
  };
};
