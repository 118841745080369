import React, { ReactNode, createContext, useContext } from 'react';
import { Parameters } from './Parameters.js';

type Value = {
  parameters: Parameters;
};

const Context = createContext<Value | undefined>(undefined);

type Props = {
  parameters: Parameters;
  children: ReactNode;
};

/**
 * Helper provider to pass route parameter values and
 * other information down to the component tree.
 */
export const RouteProvider = ({ parameters, children }: Props) => {
  return <Context.Provider value={{ parameters }}>{children}</Context.Provider>;
};

/**
 * Returns routing data.
 */
export const useRoute = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('RouteProvider is not there.');
  }
  return context;
};
