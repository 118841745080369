import { Operate } from '../Operate.js';
import { Update } from '../Update.js';
import { FunctionCache } from '../cache.js';
import { moveItem } from '../utils/moveItem.js';

const cache = new FunctionCache();

/**
 * Returns function to move the item at the given index upwards.
 */
export const createMoveIndexUp = <T>(update: Update<T[]>, index: number): Operate =>
  cache.cached(update, index, () => () => update((value: T[]) => moveItem(value, index, 'up')));
