import React from 'react';
import { createRoot } from 'react-dom/client';
import { Application } from '../components/Application.js';
import {
  setEndListener,
  setErrorListener,
  setStartListener
} from '../../packages/fetcher/fetcherFunctions.js';

// Root element for React.
const root = document.createElement('div');
document.body.insertBefore(root, document.body.firstChild);

// Setting up the AJAX spinner.
const spinnerImage = document.createElement('img');
spinnerImage.width = 220;
spinnerImage.height = 19;
spinnerImage.src = `/v-${VERSION}/img/spinner.gif`;
spinnerImage.className = 'spinner-container';
const spinnerContainer = document.createElement('div');
spinnerContainer.style.display = 'none';
spinnerContainer.appendChild(spinnerImage);
document.body.insertBefore(spinnerContainer, document.body.firstChild);

setStartListener(() => {
  spinnerContainer.style.display = 'block';
});

setEndListener(() => {
  spinnerContainer.style.display = 'none';
});

// Setting up the AJAX error container.
const errorAlert = document.createElement('div');
errorAlert.className = 'alert error';
const container = document.createElement('div');
container.className = 'container';
container.appendChild(errorAlert);
const errorContainer = document.createElement('div');
errorContainer.className = 'error-container';
errorContainer.style.display = 'none';
errorContainer.appendChild(container);
document.body.insertBefore(errorContainer, document.body.firstChild);

setErrorListener((error: unknown) => {
  errorContainer.style.display = 'block';
  errorAlert.textContent = error + '';
});

createRoot(root).render(<Application />);
