import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { getAccountItems } from '../../../api/accounting.js';
import { useFilter } from '../../provider/FilterProvider.js';
import { Account } from '../../../../organizer-datamodel/accounting/Account.js';
import { AccountItem } from '../../../../organizer-datamodel/accounting/AccountItem.js';
import { findAccount } from '../../../../organizer-datamodel/accounting/Chart.js';

type ViewState = {
  account: Account;
  items: AccountItem[];
  total: number;
  inTotal: number;
  outTotal: number;
};

const ViewContext = createContext({} as ViewState);

type Props = {
  accountId: string;
  children: ReactNode;
};

/**
 * Account view state provider.
 */
export const ViewProvider = ({ accountId, children }: Props) => {
  const { year, month, titleSearch } = useFilter();

  const [items, setItems] = useState<AccountItem[]>([]);

  const updateAccount = useCallback(async () => {
    const items = await getAccountItems(accountId, year, month);
    if (items) {
      setItems(items);
    }
  }, [year, month, accountId]);

  const filteredItems = useMemo(() => {
    const titleSearchLowerCase = titleSearch.toLowerCase();
    return items.filter((item) => item.entryTitle.toLowerCase().includes(titleSearchLowerCase));
  }, [items, titleSearch]);

  const total = useMemo(() => {
    return filteredItems.reduce((prev, item) => prev + item.change, 0);
  }, [filteredItems]);

  const inItems = useMemo(() => {
    return filteredItems.filter((item) => item.change > 0);
  }, [filteredItems]);

  const inTotal = useMemo(() => {
    return Math.abs(inItems.reduce((prev, item) => prev + item.change, 0));
  }, [inItems]);

  const outItems = useMemo(() => {
    return filteredItems.filter((item) => item.change < 0);
  }, [filteredItems]);

  const outTotal = useMemo(() => {
    return Math.abs(outItems.reduce((prev, item) => prev + item.change, 0));
  }, [outItems]);

  useEffect(() => {
    updateAccount();
  }, [updateAccount]);

  const account = findAccount(accountId);

  const value = { account, items: filteredItems, total, inTotal, outTotal };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

/**
 * Helper hook to access the account view state.
 */
export const useAccountView = () => useContext(ViewContext);
