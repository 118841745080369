import { validateIsoDate } from '../date/dateFunctions.js';

/**
 * Base type for validator functions. These functions
 * take a value and check whether the value is valid.
 * It returns the value in correct type or throws an
 * error for invalid data.
 */
export type Validator<T> = (value: unknown) => T;

/**
 * Validator for strings.
 */
export const stringValidator: Validator<string> = (value: unknown) => {
  if (typeof value !== 'string') {
    throw new Error('The value is not a string.');
  }
  return value;
};

/**
 * Validator for numbers.
 */
export const numberValidator: Validator<number> = (value: unknown) => {
  if (typeof value !== 'number') {
    throw new Error('The value is not a number.');
  }
  return value;
};

/**
 * Validator for integers.
 */
export const integerValidator: Validator<number> = (value: unknown) => {
  const number = numberValidator(value);
  if (!Number.isInteger(number)) {
    throw new Error(`Expecting an integer number, got ${number}.`);
  }
  return number;
};

/**
 * Validator for booleans.
 */
export const booleanValidator: Validator<boolean> = (value: unknown) => {
  if (typeof value !== 'boolean') {
    throw new Error('The value is not a boolean.');
  }
  return value;
};

/**
 * Validator for bigints.
 */
export const bigintValidator: Validator<bigint> = (value: unknown) => {
  if (typeof value !== 'bigint') {
    throw new Error('The value is not a bigint.');
  }
  return value;
};

/**
 * Validator for functions.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const functionValidator: Validator<Function> = (value: unknown) => {
  if (typeof value !== 'function') {
    throw new Error('The value is not a function.');
  }
  return value;
};

/**
 * Returns validator for symbols.
 */
export const symbolValidator: Validator<symbol> = (value: unknown) => {
  if (typeof value !== 'symbol') {
    throw new Error('The value is not a symbol.');
  }
  return value;
};

/**
 * Creates validator which allows null or the value of the given validator.
 */
export const nullableValidator =
  <T>(validator: Validator<T>): Validator<T | null> =>
  (value: unknown) => {
    if (value === null) {
      return null;
    }
    return validator(value);
  };

/**
 * Creates validator which allows undefined or the value of the given validator.
 */
export const optionalValidator =
  <T>(validator: Validator<T>): Validator<T | undefined> =>
  (value: unknown) => {
    if (value === undefined) {
      return;
    }
    return validator(value);
  };

/**
 * Validates the UUID version 4 string.
 */
export const uuidV4Validator: Validator<string> = (value: unknown) => {
  if (typeof value !== 'string') {
    throw new Error('The value is not a string.');
  }
  if (!value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)) {
    throw new Error('Invalid UUID version 4.');
  }
  return value;
};

/**
 * Validates ISO 8601 date part (2020-01-02).
 */
export const isoDateValidator: Validator<string> = (value: unknown) => {
  if (typeof value !== 'string') {
    throw new Error('The value is not a string.');
  }
  return validateIsoDate(value);
};

/**
 * Validates that the value is one of the given strings.
 */
export const stringEnumValidator =
  <T extends string>(allowedValues: T[]): Validator<T> =>
  (value: unknown) => {
    const string = stringValidator(value);
    if (!allowedValues.includes(string as T)) {
      throw new Error('Value is not one of the allowed strings.');
    }
    return string as T;
  };

/**
 * Validates the given object properties.
 */
export type PropertiesValidator<T> = {
  [K in keyof T]: Validator<T[K]>;
};

/**
 * Creates object validator for the given type.
 */
export const createObjectValidator = <T extends object>(
  typename: string,
  propertiesValidator: PropertiesValidator<T>
): Validator<T> => {
  return (value: unknown) => {
    const object = requireToBeObject(value, typename);
    const validatorKeys = Object.keys(propertiesValidator);
    for (const key of validatorKeys) {
      const propertyValidator = (propertiesValidator as any)[key];
      try {
        propertyValidator((object as any)[key]);
      } catch (err) {
        const currentMessage = `Property "${key}" of ${typename} is not valid.`;
        throwComposedMessage(currentMessage, err);
      }
    }
    for (const key of Object.keys(object)) {
      if (!validatorKeys.includes(key)) {
        throw new Error(`Object of ${typename} contains additional properties ("${key}").`);
      }
    }
    return value as T;
  };
};

/**
 * Creates array validator.
 */
export const createArrayValidator = <T>(itemValidator: Validator<T>): Validator<T[]> => {
  return (value: unknown) => {
    const array = requireToBeArray(value);
    for (const item of array) {
      try {
        itemValidator(item);
      } catch (err) {
        const currentMessage = `Array contains invalid value.`;
        throwComposedMessage(currentMessage, err);
      }
    }
    return array as T[];
  };
};

/**
 * Creates validator that is composed of the given validators.
 */
export const createComposedValidator = <T>(...validators: Validator<unknown>[]): Validator<T> => {
  return (value: unknown) => {
    for (const validator of validators) {
      validator(value);
    }
    return value as T;
  };
};

/**
 * Throws error when the value is not an object.
 */
export const requireToBeObject = (value: unknown, typename?: string): object => {
  if (typeof value !== 'object' || value === null) {
    if (typename === undefined) {
      throw new Error(`Value must be an object.`);
    } else {
      throw new Error(`Value of type ${typename} must be an object.`);
    }
  }
  return value;
};

/**
 * Throws error when the value is not an array.
 */
export const requireToBeArray = (value: unknown): unknown[] => {
  if (!Array.isArray(value)) {
    throw new Error(`Value must be an array.`);
  }
  return value;
};

/**
 * Throws error based on the current message and the previous error.
 */
export const throwComposedMessage = (currentMessage: string, err: unknown) => {
  const message = err instanceof Error ? `${currentMessage} ${err.message}` : `${currentMessage} ${err}`;
  throw new Error(message);
};
