import { useParameters } from './useParameters.js';

/**
 * Helper hook to access the given parameter value.
 * Returns undefined if the parameter does not exist.
 */
export const useParameter = (name: string) => {
  const parameters = useParameters();
  return parameters[name];
};
