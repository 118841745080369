import { useCallback } from 'react';

/**
 * Exposes history API.
 */
export const useHistory = () => {
  const push = useCallback((url: string | URL | null | undefined) => {
    window.history.pushState({}, '', url);
  }, []);

  return { push };
};
