import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Layout } from '../accounting/layout/Layout.js';
import { AccountingTable } from '../accounting/AccountingTable.js';
import { getComments } from '../api/comments.js';
import { CommentRow } from './CommentRow.js';
import { StoredComment } from '../../organizer-datamodel/comments/StoredComment.js';
import { requestDeleteJson } from '../../packages/fetcher/fetcherFunctions.js';
import { useRequiredParameter } from '../../packages/router/params/useRequiredParameter.js';
import { euc } from '../../packages/url/encodeUriComponents.js';

/**
 * Renders comments page for a post.
 */
export const CommentsPage = () => {
  const postId = useRequiredParameter('postId');
  const [comments, setComments] = useState<StoredComment[]>([]);

  const updateComments = useCallback(async () => {
    setComments(await getComments(postId));
  }, [postId]);

  const removeComment = useCallback(
    async (commentId: string) => {
      const url = euc`/api/comment/${commentId}`;
      await requestDeleteJson(url);
      await updateComments();
    },
    [updateComments]
  );

  useEffect(() => {
    updateComments();
  }, [updateComments]);

  return (
    <Layout title="Blog comments">
      <h2>Post summaries</h2>
      <AccountingTable>
        <thead>
          <tr>
            <th>Author</th>
            <th>Date</th>
            <th>Comment</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {comments.map((comment) => (
            <CommentRow key={comment.id} comment={comment} onRemove={removeComment} />
          ))}
        </tbody>
      </AccountingTable>
    </Layout>
  );
};
