import React from 'react';
import { NotFoundPage } from '../accounting/page/NotFound.js';
import { FrontPage } from '../accounting/page/FrontPage.js';
import { SummariesPage } from '../comments/SummariesPage.js';
import { CommentsPage } from '../comments/CommentsPage.js';
import { AuthenticatedRoute } from './auth/AuthenticatedRoute.js';
import { EntriesPage } from '../accounting/page/entries/EntriesPage.js';
import { AccountsPage } from '../accounting/page/accounts/AccountsPage.js';
import { EntryPage } from '../accounting/page/entry/view/EntryPage.js';
import { AccountPage } from '../accounting/page/account/AccountPage.js';
import { EditPage } from '../accounting/page/entry/edit/EditPage.js';
import { LoginPage } from '../accounting/page/login/LoginPage.js';
import { CommentPage } from '../comments/CommentPage.js';
import { BrowserHrefProvider } from '../../packages/router/href/browser/BrowserHrefProvider.js';
import { Router } from '../../packages/router/router/Router.js';
import { Route } from '../../packages/router/router/Route.js';
import { AnalyticsPage } from '../../components/organizer/AnalyticsPage.js';
import { AnalyticsFilePage } from '../../components/organizer/AnalyticsFilePage.js';

/**
 * The application routes.
 */
export const ApplicationRouter = () => {
  return (
    <BrowserHrefProvider>
      <Router fallback={<NotFoundPage />}>
        <Route path="/">
          <FrontPage />
        </Route>
        <Route path="/comments">
          <AuthenticatedRoute>
            <SummariesPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/comments/:postId">
          <AuthenticatedRoute>
            <CommentsPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/comment/:commentId">
          <AuthenticatedRoute>
            <CommentPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/entries">
          <AuthenticatedRoute>
            <EntriesPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/accounts">
          <AuthenticatedRoute>
            <AccountsPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/entry/:entryId">
          <AuthenticatedRoute>
            <EntryPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/account/:accountId">
          <AuthenticatedRoute>
            <AccountPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/new">
          <AuthenticatedRoute>
            <EditPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/edit/:entryId">
          <AuthenticatedRoute>
            <EditPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/copy/:copyFromEntryId">
          <AuthenticatedRoute>
            <EditPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/analytics">
          <AuthenticatedRoute>
            <AnalyticsPage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/analytics/:file">
          <AuthenticatedRoute>
            <AnalyticsFilePage />
          </AuthenticatedRoute>
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
      </Router>
    </BrowserHrefProvider>
  );
};
