import React from 'react';
import { useAuth } from '../provider/AuthProvider.js';
import { Redirect } from '../../../packages/router/href/Redirect.js';

/**
 * Container for the front page.
 */
export const FrontPage = () => {
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return <Redirect to="/entries" />;
  } else {
    return <Redirect to="/login" />;
  }
};
