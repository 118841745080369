import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EntryErrors } from './EntryErrors.js';
import {
  addItem,
  convertToEntry,
  createEntryValues,
  createInitialErrors,
  validateEntry
} from './EntryValues.js';
import { ItemInputs } from '../item/ItemInputs.js';
import { saveEntry, updateEntry } from '../../../../../api/accounting.js';
import { ChangesTable } from '../../view/ChangesTable.js';
import { LinkMap, createLinkMap } from './LinkMap.js';
import { Entry } from '../../../../../../organizer-datamodel/accounting/Entry.js';
import { EntryValues } from '../../../../../../organizer-datamodel/accounting/form/EntryValues.js';
import { useForm } from '../../../../../../packages/form/useForm.js';
import { createUpdateProperty } from '../../../../../../packages/optics/optics/property/createUpdateProperty.js';
import { createOperate } from '../../../../../../packages/optics/optics/self/createOperate.js';
import { createWriteProperty } from '../../../../../../packages/optics/optics/property/createWriteProperty.js';
import { createRemoveIndex } from '../../../../../../packages/optics/optics/index/createRemoveIndex.js';
import { createMoveIndexUp } from '../../../../../../packages/optics/optics/index/createMoveIndexUp.js';
import { createMoveIndexDown } from '../../../../../../packages/optics/optics/index/createMoveIndexDown.js';
import { useHref } from '../../../../../../packages/router/href/HrefProvider.js';
import { euc } from '../../../../../../packages/url/encodeUriComponents.js';
import { Form } from '../../../../../../packages/ui/form/Form.js';
import { FormRow } from '../../../../../../packages/ui/form/FormRow.js';
import { Input } from '../../../../../../packages/ui/form/Input.js';
import { Alert } from '../../../../../../packages/ui/Alert.js';
import { Buttons } from '../../../../../../packages/ui/form/Buttons.js';
import { Button } from '../../../../../../packages/ui/button/Button.js';

type Props = {
  entry?: Entry;
  type: 'new' | 'edit';
  isCopy: boolean;
};

/**
 * Renders form to create new entries or update existing ones.
 */
export const EntryForm = ({ entry, type, isCopy }: Props) => {
  const { setHref } = useHref();

  const submit = useCallback(
    async (values: EntryValues) => {
      const newEntry = convertToEntry(values);
      if (type === 'new') {
        const entryId = await saveEntry(newEntry);
        setHref(euc`/entry/${entryId}`);
      } else if (type === 'edit' && entry) {
        await updateEntry(entry.id, newEntry);
        setHref(euc`/entry/${entry.id}`);
      }
    },
    [entry, setHref, type]
  );

  const handleSubmissionError = useCallback((err: unknown) => {
    return { generic: err + '', items: [] };
  }, []);

  const [linkMap, setLinkMap] = useState<LinkMap>({ dates: {}, amounts: {} });

  const initialValues = useMemo(() => createEntryValues(entry), [entry]);
  const initialErrors = useMemo(() => createInitialErrors(entry), [entry]);

  useEffect(() => {
    setLinkMap(createLinkMap(initialValues));
  }, [initialValues]);

  const { values, setValues, handleSubmit, errors } = useForm<EntryValues, EntryErrors>({
    initialValues,
    initialErrors,
    validate: validateEntry,
    submit,
    handleSubmissionError
  });

  const handleItemsChange = createUpdateProperty(setValues, 'items');
  const handleAddItem = createOperate(handleItemsChange, addItem);

  const displayedEntryId = entry && type === 'edit' ? entry.id : '';
  const saveLabel = isCopy ? 'Save copy' : type === 'new' ? 'Save new' : 'Update';

  return (
    <>
      <h2>{values.title}</h2>
      <div style={{ minHeight: '200px' }}>
        <ChangesTable entry={values} />
      </div>
      <Form onSubmit={handleSubmit}>
        <FormRow columns={2}>
          <Input
            label="Title"
            error={errors.title}
            value={values.title}
            placeholder="Title"
            onChange={createWriteProperty(setValues, 'title')}
          />
          <Input label="ID" value={displayedEntryId} readOnly={true} disabled={true} />
        </FormRow>
        {values.items.map((_, index) => (
          <ItemInputs
            key={index}
            index={index}
            numOfItems={values.items.length}
            errors={errors.items[index] ?? {}}
            values={values.items[index]}
            onRemove={createRemoveIndex(handleItemsChange, index)}
            onUpdateItems={handleItemsChange}
            onUp={createMoveIndexUp(handleItemsChange, index)}
            onDown={createMoveIndexDown(handleItemsChange, index)}
            linkMap={linkMap}
            onUpdateLinkMap={setLinkMap}
          />
        ))}
        {errors.generic && (
          <FormRow columns={1}>
            <Alert type="error">{errors.generic}</Alert>
          </FormRow>
        )}
        <FormRow columns={1}>
          <Buttons>
            <Button onClick={handleAddItem}>Add item</Button>
            <Button type="submit">{saveLabel}</Button>
          </Buttons>
        </FormRow>
      </Form>
    </>
  );
};
