import React from 'react';
import { AccountingTable } from '../../AccountingTable.js';
import { ItemRow } from './ItemRow.js';
import { useAccountView } from './ViewProvider.js';

/**
 * Renders the items table for the account.
 */
export const ItemsTable = () => {
  const { items } = useAccountView();

  return (
    <AccountingTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
          <th>Item title</th>
          <th>Debit or credit</th>
          <th className="text-right">Amount (EUR)</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <ItemRow key={index} item={item} />
        ))}
      </tbody>
    </AccountingTable>
  );
};
