import { Operate } from '../Operate.js';
import { Update } from '../Update.js';
import { FunctionCache } from '../cache.js';

const cache = new FunctionCache();

/**
 * Returns function to remove the item at the given index.
 */
export const createRemoveIndex = <T>(update: Update<T[]>, index: number): Operate =>
  cache.cached(update, index, () => () => update((value: T[]) => value.filter((_, i) => i !== index)));
