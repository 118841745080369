import React, { createContext, ReactNode, useContext, useState } from 'react';

export type AuthState = {
  isLoggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
};

/**
 * The login store context.
 */
const AuthContext = createContext({} as AuthState);

/**
 * The login store provider.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setLoggedIn] = useState(!!(window as any).loggedIn);

  return <AuthContext.Provider value={{ isLoggedIn, setLoggedIn }}>{children}</AuthContext.Provider>;
};

/**
 * Hook to access the login state.
 */
export const useAuth = () => useContext(AuthContext);
