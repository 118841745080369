import { Item, validatorOfItem } from './Item.js';
import { AccountItem } from './AccountItem.js';
import { findAccount } from './Chart.js';
import { creditEffect, debitEffect } from './accountEffect.js';
import { createCachedFunction } from '../utils/cacheUtils.js';
import { EntrySummary } from './EntrySummary.js';
import { extractMonth } from '../../packages/date/dateFunctions.js';
import {
  createArrayValidator,
  createObjectValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';

/**
 * One accounting entry (transaction).
 */
export type Entry = {
  /**
   * Entry ID.
   */
  id: string;
  /**
   * Title of the entry.
   */
  title: string;
  /**
   * Items of the entry.
   */
  items: Item[];
};

export const validatorOfEntry = createObjectValidator<Entry>('Entry', {
  id: uuidV4Validator,
  title: stringValidator,
  items: createArrayValidator(validatorOfItem)
});

/**
 * Checks whether the entry has an item with date in the
 * given range.
 */
export const hasDateInRange = (entry: Entry, start: string, end: string) => {
  return entry.items.some((item) => item.date >= start && item.date <= end);
};

/**
 * Collects account items from this entry.
 */
export const collectAccountItems = (
  entry: Entry,
  accountId: string,
  start: string,
  end: string,
  items: AccountItem[]
) => {
  const account = findAccount(accountId);
  for (const item of entry.items) {
    if (item.date >= start && item.date <= end) {
      if (item.debit === accountId) {
        const multiplier = debitEffect(account.type);
        const effect = multiplier * item.eurAmount;
        items.push({
          entryId: entry.id,
          entryTitle: entry.title,
          itemTitle: item.title,
          itemDate: item.date,
          itemEffect: 'debit',
          change: effect
        });
      } else if (item.credit === account.id) {
        const multiplier = creditEffect(account.type);
        const effect = multiplier * item.eurAmount;
        items.push({
          entryId: entry.id,
          entryTitle: entry.title,
          itemTitle: item.title,
          itemDate: item.date,
          itemEffect: 'credit',
          change: effect
        });
      }
    }
  }
};

/**
 * Finds earliest date from the entry items. Returns
 * 9999-99-99 when no entry exists. This function is cached.
 */
export const findEarliestDate = createCachedFunction((entry: Entry) => {
  let earliest = '9999-99-99';
  for (const item of entry.items) {
    if (item.date < earliest) {
      earliest = item.date;
    }
  }
  return earliest;
});

export type MonthEntries = {
  monthName: string;
  entries: EntrySummary[];
};

/**
 * Groups given entries by month.
 */
export const groupByMonth = (entries: EntrySummary[]) => {
  const months: MonthEntries[] = [
    {
      monthName: 'January',
      entries: []
    },
    {
      monthName: 'February',
      entries: []
    },
    {
      monthName: 'March',
      entries: []
    },
    {
      monthName: 'April',
      entries: []
    },
    {
      monthName: 'May',
      entries: []
    },
    {
      monthName: 'June',
      entries: []
    },
    {
      monthName: 'July',
      entries: []
    },
    {
      monthName: 'August',
      entries: []
    },
    {
      monthName: 'September',
      entries: []
    },
    {
      monthName: 'October',
      entries: []
    },
    {
      monthName: 'November',
      entries: []
    },
    {
      monthName: 'December',
      entries: []
    }
  ];
  for (const entry of entries) {
    const month = extractMonth(entry.date);
    months[month].entries.push(entry);
  }
  return months.filter((month) => month.entries.length > 0).reverse();
};
