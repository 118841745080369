import React from 'react';
import { creditAccountOptions, debitAccountOptions } from './accountOptions.js';
import { DebitOrCredit } from '../../../../../../organizer-datamodel/accounting/DebitOrCredit.js';
import { Select } from '../../../../../../packages/ui/form/select/Select.js';

type Props = {
  type: DebitOrCredit;
  error?: string;
  value: string;
  onChange: (value: string) => void;
};

/**
 * Renders account selector (debit or credit) for item forms.
 */
export const AccountSelector = ({ type, error, value, onChange }: Props) => {
  const label = type === 'debit' ? 'Debit' : 'Credit';
  const options = type === 'debit' ? debitAccountOptions : creditAccountOptions;

  return <Select label={label} value={value} options={options} error={error} onChange={onChange} />;
};
