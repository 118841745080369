import React, { useEffect, useState } from 'react';
import { Layout } from '../../organizer-frontend/accounting/layout/Layout.js';
import { FixedTable } from '../../packages/ui/table/quick/FixedTable.js';
import { Column } from '../../packages/ui/table/quick/Column.js';
import { getAnalyticsFiles } from '../../frontend-api/analytics.js';
import { Link } from '../../packages/router/Link.js';

/**
 * Renders analytics file list.
 */
export const AnalyticsPage = () => {
  const [files, setFiles] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setFiles(await getAnalyticsFiles());
    })();
  }, []);

  const columns: Column<string>[] = [
    {
      title: 'Analytics file',
      render: (row: string) => <Link to={`/analytics/${row}`}>{row}</Link>
    }
  ];

  return (
    <Layout title="Analytics">
      <h2>Analytics</h2>
      <FixedTable columns={columns} rows={files} fontSize="tiny" />
    </Layout>
  );
};
