import React, { memo, useCallback, useRef } from 'react';
import { InputWrapper } from './InputWrapper.js';
import { useId } from '../useId.js';
import { clsx } from 'clsx';

type Props = {
  label?: string;
  accept: string;
  onChange: (files: FileList) => void;
  error?: string;
};

/**
 * Renders the files input for uploading.
 */
export const FilesInput = memo(function FilesInput({ accept, onChange, label, error }: Props) {
  const labelClass = clsx({ 'error': error });
  const inputClass = clsx({ 'error': error });
  const helpClass = clsx('help', { 'error': error });

  const ref = useRef<HTMLInputElement>(null);
  const id = useId();

  const handleChange = useCallback(() => {
    const input = ref.current;
    if (input) {
      const files = input.files;
      if (files) {
        onChange(files);
      }
    }
  }, [onChange]);

  return (
    <InputWrapper>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        type="file"
        className={inputClass}
        multiple={true}
        accept={accept}
        onChange={handleChange}
      />
      {error && <div className={helpClass}>{error}</div>}
    </InputWrapper>
  );
});
