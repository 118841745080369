import { AccountChange } from './AccountChange.js';
import { accounts, findAccount, hasAccount } from './Chart.js';
import { creditEffect, debitEffect } from './accountEffect.js';

/**
 * Helper class for calculating changes in account balances.
 */
export class ChangeMap {
  readonly changes: Map<string, number>;

  constructor() {
    this.changes = new Map<string, number>();
  }

  /**
   * Adds to the account balance.
   */
  add(accountId: string, change: number) {
    const previous = this.changes.get(accountId) || 0;
    this.changes.set(accountId, previous + change);
  }

  /**
   * Returns the changes as an array of AccountChange instances.
   */
  asArray() {
    const ret: AccountChange[] = [];
    for (const account of accounts) {
      const change = this.changes.get(account.id);
      if (change !== undefined) {
        ret.push({ account, change });
      }
    }
    return ret;
  }
}

export type ItemLike = {
  eurAmount: number | string;
  debit: string;
  credit: string;
};

export type EntryLike = {
  items: ItemLike[];
};

/**
 * Calculates changes to accounts from this entry.
 */
export const calculateChanges = (entry: EntryLike) => {
  const changes = new ChangeMap();
  for (const item of entry.items) {
    if (!hasAccount(item.debit) || !hasAccount(item.credit)) {
      continue;
    }

    const eurAmount =
      typeof item.eurAmount === 'string' ? Math.round(parseFloat(item.eurAmount) * 100) : item.eurAmount;
    if (Number.isNaN(eurAmount)) {
      continue;
    }

    const debitAccount = findAccount(item.debit);
    const creditAccount = findAccount(item.credit);

    changes.add(item.debit, debitEffect(debitAccount.type) * eurAmount);
    changes.add(item.credit, creditEffect(creditAccount.type) * eurAmount);
  }
  return changes;
};
