import { Modify } from '../Modify.js';
import { Operate } from '../Operate.js';
import { Update } from '../Update.js';
import { OperationCache } from '../cache.js';

const cache = new OperationCache();

/**
 * Creates stable "operate" function for the given "update"
 * and "modify" functions.
 */
export const createOperate = <T>(update: Update<T>, op: Modify<T>): Operate =>
  cache.cached(update, op, () => () => update(op));
