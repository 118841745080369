import React from 'react';
import { ReactNode } from 'react';

type Width = 'fixed' | 'fixed-wide' | 'fluid';

type Props = {
  width?: Width;
  children: ReactNode;
};

/**
 * Renders the main page container.
 */
export const Container = ({ width = 'fixed', children }: Props) => {
  return <div className={getClassName(width)}>{children}</div>;
};

const getClassName = (width: Width) => {
  if (width === 'fixed-wide') {
    return 'container-wide';
  } else if (width === 'fluid') {
    return 'container-fluid';
  } else {
    return 'container';
  }
};
