import React from 'react';
import { FilterProvider } from '../accounting/provider/FilterProvider.js';
import { AuthProvider } from '../accounting/provider/AuthProvider.js';
import { ApplicationRouter } from './ApplicationRouter.js';

/**
 * Main entrypoint to the application. Sets up global state contexts
 * and the application routes.
 */
export const Application = () => {
  return (
    <AuthProvider>
      <FilterProvider>
        <ApplicationRouter />
      </FilterProvider>
    </AuthProvider>
  );
};
