import {
  createObjectValidator,
  numberValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';
import { DebitOrCredit, validatorOfDebitOrCredit } from './DebitOrCredit.js';

/**
 * One item (row) under an account.
 */
export type AccountItem = {
  /**
   * Referenced entry ID.
   */
  entryId: string;
  /**
   * The referenced entry title.
   */
  entryTitle: string;
  /**
   * Item of the referenced entry.
   */
  itemTitle: string;
  /**
   * Item date in string format YYYY-MM-DD.
   */
  itemDate: string;
  /**
   * Whether this item debits or credits to the account.
   */
  itemEffect: DebitOrCredit;
  /**
   * Actual effect on the account balance.
   */
  change: number;
};

export const validatorOfAccountItem = createObjectValidator<AccountItem>('AccountItem', {
  entryId: uuidV4Validator,
  entryTitle: stringValidator,
  itemTitle: stringValidator,
  itemDate: stringValidator,
  itemEffect: validatorOfDebitOrCredit,
  change: numberValidator
});
