import { EntrySummary, validatorOfEntrySummary } from '../../organizer-datamodel/accounting/EntrySummary.js';
import { Entry, validatorOfEntry } from '../../organizer-datamodel/accounting/Entry.js';
import { EntityFile, validatorOfEntityFile } from '../../organizer-datamodel/upload/EntityFile.js';
import { AccountItem, validatorOfAccountItem } from '../../organizer-datamodel/accounting/AccountItem.js';
import { NewEntry } from '../../organizer-datamodel/accounting/NewEntry.js';
import { createArrayValidator, uuidV4Validator } from '../../packages/validator/validator.js';
import { requestJSON, uploadFiles } from '../../packages/fetcher/fetcherFunctions.js';
import { euc } from '../../packages/url/encodeUriComponents.js';

/**
 * Retrieves entries.
 */
export const getEntries = async (year: string, month: string) => {
  const entries = await requestJSON<EntrySummary[], undefined>(
    euc`/api/accounting/entries/${year}/${month}`,
    'GET',
    undefined
  );
  return createArrayValidator(validatorOfEntrySummary)(entries);
};

/**
 * Retrieves one entry.
 */
export const getEntry = async (entryId: string) => {
  const entry = await requestJSON<Entry, undefined>(euc`/api/accounting/entry/${entryId}`, 'GET', undefined);
  return validatorOfEntry(entry);
};

/**
 * Retrieves one entry files.
 */
export const getEntryFiles = async (entryId: string) => {
  const files = await requestJSON<EntityFile[], undefined>(
    euc`/api/accounting/entry/${entryId}/files`,
    'GET',
    undefined
  );
  return createArrayValidator(validatorOfEntityFile)(files);
};

/**
 * Retrieves account items.
 */
export const getAccountItems = async (accountId: string, year: string, month: string) => {
  const accountItems = await requestJSON<AccountItem[], undefined>(
    euc`/api/accounting/account/${accountId}/items/${year}/${month}`,
    'GET',
    undefined
  );
  return createArrayValidator(validatorOfAccountItem)(accountItems);
};

/**
 * Saves new entry.
 */
export const saveEntry = async (entry: NewEntry) => {
  const entryId = await requestJSON<string, NewEntry>(euc`/api/accounting/entry`, 'POST', entry);
  return uuidV4Validator(entryId);
};

/**
 * Updates an existing entry.
 */
export const updateEntry = async (entryId: string, entry: NewEntry) => {
  await requestJSON<void, NewEntry>(euc`/api/accounting/entry/${entryId}`, 'PUT', entry);
};

/**
 * Uploads files to the entry.
 */
export const uploadEntryFiles = async (entryId: string, formData: FormData) => {
  await uploadFiles(euc`/api/accounting/entry/${entryId}/files`, formData);
};

/**
 * Removes the given entry.
 */
export const removeEntry = async (entryId: string) => {
  await requestJSON<void, undefined>(euc`/api/accounting/entry/${entryId}`, 'DELETE', undefined);
};

/**
 * Removes the given entry file.
 */
export const removeEntryFile = async (entryId: string, fileId: string) => {
  await requestJSON<void, undefined>(
    euc`/api/accounting/entry/${entryId}/file/${fileId}`,
    'DELETE',
    undefined
  );
};
