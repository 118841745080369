import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from '../../../layout/Layout.js';
import { getEntry } from '../../../../api/accounting.js';
import { EntryForm } from './entry/EntryForm.js';
import { Entry } from '../../../../../organizer-datamodel/accounting/Entry.js';
import { useParameter } from '../../../../../packages/router/params/useParameter.js';

/**
 * Shows the entry form page.
 */
export const EditPage = () => {
  const entryId = useParameter('entryId');
  const copyFromEntryId = useParameter('copyFromEntryId');

  const [entry, setEntry] = useState<Entry>();
  const [loaded, setLoaded] = useState(false);

  const loadEntry = useCallback(async (entryId: string) => {
    setEntry(await getEntry(entryId));
  }, []);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      if (copyFromEntryId) {
        await loadEntry(copyFromEntryId);
      } else if (entryId) {
        await loadEntry(entryId);
      }
      setLoaded(true);
    })();
  }, [copyFromEntryId, entryId, loadEntry]);

  const title = copyFromEntryId ? 'Edit copy' : 'Edit entry';
  const type = entryId ? 'edit' : 'new';

  return (
    <Layout title={title}>
      {loaded && <EntryForm entry={entry} type={type} isCopy={copyFromEntryId !== undefined} />}
    </Layout>
  );
};
