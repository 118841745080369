/**
 * Returns the current time Unix timestamp.
 */
export const currentUnixTimestamp = () => Math.floor(Date.now() / 1000);

/**
 * Formats elapsed time in seconds to hours, minutes,
 * and seconds.
 */
export const formatElapsed = (elapsed: number) => {
  const seconds = elapsed % 60;
  const minutes = Math.floor(elapsed / 60) % 60;
  const hours = Math.min(Math.floor(elapsed / 3600), 99);
  const hoursPadded = hours.toString().padStart(2, '0');
  const minutesPadded = minutes.toString().padStart(2, '0');
  const secondsPadded = seconds.toString().padStart(2, '0');
  return `${hoursPadded}:${minutesPadded}:${secondsPadded}`;
};

/**
 * Converts milliseconds to rounded seconds.
 */
export const millisecondsToWholeSeconds = (milliseconds: number) => Math.floor(milliseconds / 1000);

/**
 * Formats ISO date in Estonian format.
 */
export const formatDate = (date: string) => {
  const match = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) {
    throw new Error(`Invalid date: ${date}.`);
  }
  return `${match[3]}.${match[2]}.${match[1]}`;
};

/**
 * Formats Unix timestamp in Estonian date format.
 */
export const formatUnixTimestampDate = (unixTimestamp: number) => {
  return formatDate(getUnixTimestampDate(unixTimestamp));
};

/**
 * Returns 0-based month number.
 */
export const extractMonth = (date: string) => {
  const match = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) {
    throw new Error(`Invalid date: ${date}.`);
  }
  return parseInt(match[2], 10) - 1;
};

/**
 * Returns year number from the date.
 */
export const extractYear = (date: string) => {
  const match = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) {
    throw new Error(`Invalid date: ${date}.`);
  }
  return parseInt(match[1], 10);
};

/**
 * Parses the date in Estonian format.
 */
export const parseDate = (text: string) => {
  const match = text.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
  if (match) {
    return `${match[3]}-${match[2]}-${match[1]}`;
  } else {
    return null;
  }
};

/**
 * Returns today's date as ISO 8601 date part.
 */
export const getTodayISO = () => {
  return new Date().toISOString().substring(0, 10);
};

/**
 * Returns today's date as Estonian date.
 */
export const getToday = () => {
  return formatDate(getTodayISO());
};

/**
 * Converts Unix timestamp into ISO 8601 date part.
 */
export const getUnixTimestampDate = (unixTimestamp: number) =>
  new Date(unixTimestamp * 1000).toISOString().substring(0, 10);

/**
 * Validates ISO 8601 date part. Throws in case of an invalid date.
 */
export const validateIsoDate = (isoDate: string): string => {
  const match = isoDate.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) {
    throw new Error('Invalid ISO 8601 date part: does not match pattern.');
  }

  const month = parseInt(match[2]);
  const day = parseInt(match[3]);

  if (month > 12 || month < 1) {
    throw new Error(`Invalid month: ${month}.`);
  }

  if (day > 31 || day < 1) {
    throw new Error(`Invalid day: ${day}`);
  }

  return isoDate;
};

/**
 * Converts ISO 8601 date part into full ISO 8601 date-time.
 */
export const convertIsoToFull = (isoDate: string) => {
  return `${validateIsoDate(isoDate)}T00:00:00.000Z`;
};
