import { ReactNode } from 'react';
import { Redirect } from '../href/Redirect.js';
import React from 'react';

type Props = {
  /**
   * Callback that implements the authentication check.
   */
  isAllowed: () => boolean;
  /**
   * Node to render when the isAllowed callback returns true.
   */
  children: ReactNode;
  /**
   * Location to redirect when the isAllower callback returns false.
   */
  redirectTo: string | URL;
};

/**
 * Helper component to implement authenticated private routes.
 */
export const PrivateRoute = ({ isAllowed, children, redirectTo }: Props) => {
  return isAllowed() ? <>{children}</> : <Redirect to={redirectTo} />;
};
