import { Entry } from '../../../../../../organizer-datamodel/accounting/Entry.js';
import { NewEntry } from '../../../../../../organizer-datamodel/accounting/NewEntry.js';
import { EntryValues } from '../../../../../../organizer-datamodel/accounting/form/EntryValues.js';
import { ItemValues } from '../../../../../../organizer-datamodel/accounting/form/ItemValues.js';
import { extractYear, parseDate } from '../../../../../../packages/date/dateFunctions.js';
import { convertToItem, createItemValues, validateItem } from '../item/ItemValues.js';
import { EntryErrors } from './EntryErrors.js';

/**
 * Creates initial entry values.
 */
export const createEntryValues = (entry?: Entry): EntryValues => {
  const values: EntryValues = {
    title: '',
    items: [createItemValues()]
  };

  return entry ? setEntryValues(values, entry) : values;
};

/**
 * Creates initial errors structure for the entry.
 */
export const createInitialErrors = (entry?: Entry): EntryErrors => {
  if (entry) {
    return { items: entry.items.map(() => ({})) };
  } else {
    return { items: [{}] };
  }
};

/**
 * Converts values back to entry.
 */
export const convertToEntry = (values: EntryValues): NewEntry => {
  return { title: values.title, items: values.items.map((item) => convertToItem(item)) };
};

/**
 * Validates entry values. Items are validates separatedly.
 */
export const validateEntry = async (values: EntryValues): Promise<EntryErrors> => {
  const errors: EntryErrors = { items: values.items.map((item) => validateItem(item)) };

  if (values.title.trim() === '') {
    errors.title = 'Entry title needs to be set.';
  }

  if (hasCrossYearItems(values)) {
    errors.generic = 'Cross-year entries are not allowed.';
  }

  if (values.items.length === 0) {
    errors.generic = 'An entry with no items is not permitted.';
  }

  return errors;
};

/**
 * Updates entry values based on actual entry.
 */
const setEntryValues = (values: EntryValues, entry: Entry): EntryValues => {
  return { ...values, title: entry.title, items: entry.items.map((item) => createItemValues(item)) };
};

/**
 * Adds new item to the array of items.
 */
export const addItem = (items: ItemValues[]) => [...items, createItemValues()];

/**
 * Checks that all items are on the same year.
 */

const hasCrossYearItems = (values: EntryValues) => {
  const years = new Set<number>();
  for (const item of values.items) {
    const date = parseDate(item.date);
    if (date) {
      years.add(extractYear(date));
    }
  }
  return years.size > 1;
};
