import React from 'react';
import { FormattedAmount } from '../FormattedAmount.js';
import { useAccountView } from './ViewProvider.js';

/**
 * Renders the total amount for the account.
 */
export const AccountTotal = () => {
  const { total, inTotal, outTotal } = useAccountView();

  return (
    <div className="account-total">
      Total: <FormattedAmount cents={total} /> EUR, in: <FormattedAmount cents={inTotal} /> EUR, out:{' '}
      <FormattedAmount cents={outTotal} /> EUR.
    </div>
  );
};
