import {
  createObjectValidator,
  stringEnumValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';

/**
 * A file associated with an entity.
 */
export type EntityFile = {
  /**
   * UUID v4 of the file.
   */
  fileId: string;
  /**
   * UUID v4 of the associated entity.
   */
  entityId: string;
  /**
   * Original file name.
   */
  filename: string;
  /**
   * Source of the file.
   */
  source: 'accounting' | 'calendar';
};

export const validatorOfEntityFile = createObjectValidator<EntityFile>('EntityFile', {
  fileId: uuidV4Validator,
  entityId: uuidV4Validator,
  filename: stringValidator,
  source: stringEnumValidator(['accounting', 'calendar'])
});
