import React from 'react';
import { useAccountView } from './ViewProvider.js';

/**
 * Renders the account title.
 */
export const AccountTitle = () => {
  const { account } = useAccountView();
  return (
    <h2>
      Account {account.code} ({account.name})
    </h2>
  );
};
