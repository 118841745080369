import {
  booleanValidator,
  createObjectValidator,
  numberValidator,
  optionalValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';

/**
 * One comment stored by the the comments database.
 */
export type StoredComment = {
  /**
   * Comment ID.
   */
  id: string;
  /**
   * Name of the comment author.
   */
  author: string;
  /**
   * Markdown content of the comment.
   */
  content: string;
  /**
   * Unix timestamp of the comment.
   */
  timestamp: number;
  /**
   * Email of the comment author. Was used in the Blog-Core
   * system.
   */
  email?: string;
  /**
   * UUID version 4 of the post ID.
   */
  postId: string;
  /**
   * Website of the comment author.
   */
  site?: string;
  /**
   * Whether to notify about replies and new comments. Was used
   * in the Blog-Core system.
   */
  notify: boolean;
  /**
   * Slug or the article.
   */
  slug: string;
  /**
   * Title of the article.
   */
  title: string;
};

export const validatorOfStoredComment = createObjectValidator<StoredComment>('StoredComment', {
  id: uuidV4Validator,
  author: stringValidator,
  content: stringValidator,
  timestamp: numberValidator,
  email: optionalValidator(stringValidator),
  postId: uuidV4Validator,
  site: optionalValidator(stringValidator),
  notify: booleanValidator,
  slug: stringValidator,
  title: stringValidator
});
