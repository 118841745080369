import React from 'react';
import { FormattedAmount } from '../FormattedAmount.js';
import { EntryLink } from '../links/entry/EntryLink.js';
import { AccountItem } from '../../../../organizer-datamodel/accounting/AccountItem.js';
import { formatDate } from '../../../../packages/date/dateFunctions.js';

type Props = {
  item: AccountItem;
};

/**
 * Renders one row in the account items table.
 */
export const ItemRow = ({ item }: Props) => {
  return (
    <tr>
      <td>{formatDate(item.itemDate)}</td>
      <td>
        <EntryLink entryId={item.entryId}>{item.entryTitle}</EntryLink>
      </td>
      <td>{item.itemTitle}</td>
      <td>{item.itemEffect}</td>
      <td className="text-right">
        <FormattedAmount cents={item.change} />
      </td>
    </tr>
  );
};
