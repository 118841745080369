import React, { useEffect, useState } from 'react';
import { Layout } from '../accounting/layout/Layout.js';
import { AccountingTable } from '../accounting/AccountingTable.js';
import { getPostSummaries } from '../api/comments.js';
import { SummaryRow } from './SummaryRow.js';
import { PostSummary } from '../../organizer-datamodel/comments/PostSummary.js';

/**
 * Renders comment summaries.
 */
export const SummariesPage = () => {
  const [summaries, setSummaries] = useState<PostSummary[]>([]);

  useEffect(() => {
    (async () => {
      setSummaries(await getPostSummaries());
    })();
  }, []);

  return (
    <Layout title="Blog comments">
      <h2>Post summaries</h2>
      <AccountingTable>
        <thead>
          <tr>
            <th>Title</th>
            <th>Last comment date</th>
            <th>Comments count</th>
          </tr>
        </thead>
        <tbody>
          {summaries.map((summary) => (
            <SummaryRow key={summary.postId} summary={summary} />
          ))}
        </tbody>
      </AccountingTable>
    </Layout>
  );
};
