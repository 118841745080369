import React from 'react';
import { useEntryView } from './ViewProvider.js';
import { EntityFile } from '../../../../../organizer-datamodel/upload/EntityFile.js';
import { euc } from '../../../../../packages/url/encodeUriComponents.js';
import { Button } from '../../../../../packages/ui/button/Button.js';

type Props = {
  file: EntityFile;
};

/**
 * Renders one row under the files section.
 */
export const FileRow = ({ file }: Props) => {
  const { entry, removeFile } = useEntryView();

  return (
    <tr>
      <td>
        <a target="_blank" rel="noreferrer" href={euc`/api/accounting/entry/${entry.id}/file/${file.fileId}`}>
          {file.filename}
        </a>
      </td>
      <td>
        <Button size="small" onClick={() => removeFile(file.fileId)}>
          Remove
        </Button>
      </td>
    </tr>
  );
};
