import React from 'react';
import { useEntryView } from './ViewProvider.js';

/**
 * Renders the entry title.
 */
export const EntryTitle = () => {
  const { entry } = useEntryView();
  return <h2>{entry.title}</h2>;
};
