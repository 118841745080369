import React from 'react';
import { EntryTitle } from './EntryTitle.js';
import { ItemsTable } from './ItemsTable.js';
import { useEntryView } from './ViewProvider.js';
import { ChangesTable } from './ChangesTable.js';
import { Files } from './Files.js';
import { ViewButtons } from './ViewButtons.js';

/**
 * Renders the entry view.
 */
export const EntryView = () => {
  const { entry } = useEntryView();

  return (
    <>
      <EntryTitle />
      <ItemsTable />
      <ChangesTable entry={entry} />
      <Files />
      <ViewButtons />
    </>
  );
};
