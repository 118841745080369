import React from 'react';
import { AccountingTable } from '../../../AccountingTable.js';
import { useEntryView } from './ViewProvider.js';
import { ItemRow } from './ItemRow.js';

/**
 * Renders the items table for the entry view.
 */
export const ItemsTable = () => {
  const { entry } = useEntryView();

  return (
    <AccountingTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Debit</th>
          <th>Credit</th>
          <th className="text-right">Original amount</th>
          <th>Currency</th>
          <th className="text-right">EUR amount</th>
        </tr>
      </thead>
      <tbody>
        {entry.items.map((item, index) => (
          <ItemRow key={index} item={item} />
        ))}
      </tbody>
    </AccountingTable>
  );
};
