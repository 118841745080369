import React from 'react';
import { PostSummary } from '../../organizer-datamodel/comments/PostSummary.js';
import { formatUnixTimestampDate } from '../../packages/date/dateFunctions.js';
import { Link } from '../../packages/router/Link.js';

type Props = {
  summary: PostSummary;
};

/**
 * Renders one row under the post comments summaries table.
 */
export const SummaryRow = ({ summary }: Props) => {
  return (
    <tr>
      <td>
        <a href={`https://rlaanemets.com/${summary.slug}`} target="_blank" rel="noreferrer">
          {summary.title}
        </a>
      </td>
      <td>{formatUnixTimestampDate(summary.lastCommentDate)}</td>
      <td>
        <Link to={`/comments/${summary.postId}`}>{summary.commentCount}</Link>
      </td>
    </tr>
  );
};
