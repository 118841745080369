import { AccountType } from './AccountType.js';

export const debitEffect = (accountType: AccountType) => {
  switch (accountType) {
    case 'asset':
      return 1;
    case 'bank':
      return 1;
    case 'cash':
      return 1;
    case 'equity':
      return -1;
    case 'expense':
      return 1;
    case 'income':
      return -1;
    case 'liability':
      return -1;
    default:
      throw new Error(`Unknown account type: ${accountType}.`);
  }
};

export const creditEffect = (accountType: AccountType) => {
  return -1 * debitEffect(accountType);
};
