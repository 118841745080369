import React, { FormEvent, useCallback, useState } from 'react';
import { useFilter } from './provider/FilterProvider.js';
import { SelectOption } from '../../packages/ui/form/select/SelectOption.js';
import { Form } from '../../packages/ui/form/Form.js';
import { FormRow } from '../../packages/ui/form/FormRow.js';
import { Select } from '../../packages/ui/form/select/Select.js';
import { Input } from '../../packages/ui/form/Input.js';

const currentYear = new Date().getUTCFullYear();
const YEARS: string[] = [];
for (let year = 2012; year <= currentYear + 1; year++) {
  YEARS.push(year.toString());
}

const yearOptions: SelectOption[] = YEARS.map((year) => ({ value: year, label: year }));
const monthOptions: SelectOption[] = [
  { label: '*', value: '*' },
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
];

/**
 * Renders the main filter/search form.
 */
export const FilterForm = () => {
  const { year, month, titleSearch, setPeriod, setTitleSearch } = useFilter();

  const [title, setTitle] = useState(titleSearch);

  const handleYearChange = useCallback(
    (value: string) => {
      setPeriod(value, '*');
    },
    [setPeriod]
  );

  const handleMonthChange = useCallback(
    (value: string) => {
      setPeriod(year, value);
    },
    [setPeriod, year]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setTitleSearch(title);
    },
    [setTitleSearch, title]
  );

  return (
    <div style={{ width: `clamp(200px, 25%, 400px)` }}>
      <Form onSubmit={handleSubmit} gap="small">
        <FormRow columns={1}>
          <Select value={year} options={yearOptions} onChange={handleYearChange} />
        </FormRow>
        <FormRow columns={1}>
          <Select value={month} options={monthOptions} onChange={handleMonthChange} />
        </FormRow>
        <FormRow columns={1}>
          <Input type="text" value={title} onChange={setTitle} placeholder="Title search" />
        </FormRow>
      </Form>
    </div>
  );
};
