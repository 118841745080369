import { Parameters } from '../Parameters.js';
import { useRoute } from '../RouteProvider.js';

/**
 * Helper hook to use route parameters.
 */
export const useParameters = <T = Parameters>() => {
  const data = useRoute();
  return data.parameters as T;
};
