import React from 'react';
import { FormattedAmount } from '../../FormattedAmount.js';
import { AccountLink } from '../../links/AccountLink.js';
import { Item } from '../../../../../organizer-datamodel/accounting/Item.js';
import { formatDate } from '../../../../../packages/date/dateFunctions.js';

type Props = {
  item: Item;
};

/**
 * Renders one item row in the items table.
 */
export const ItemRow = ({ item }: Props) => {
  return (
    <tr>
      <td>{formatDate(item.date)}</td>
      <td>
        <AccountLink accountId={item.debit} />
      </td>
      <td>
        <AccountLink accountId={item.credit} />
      </td>
      <td className="text-right">
        <FormattedAmount cents={item.amount} />
      </td>
      <td>{item.currency.toUpperCase()}</td>
      <td className="text-right">
        <FormattedAmount cents={item.eurAmount} />
      </td>
    </tr>
  );
};
