import { ReactNode, useCallback } from 'react';
import { useAuth } from '../../accounting/provider/AuthProvider.js';
import React from 'react';
import { PrivateRoute } from '../../../packages/router/route/PrivateRoute.js';

type Props = {
  children: ReactNode;
};

/**
 * Helper component to check whether the user is authenticated.
 */
export const AuthenticatedRoute = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();

  const isAllowed = useCallback(() => {
    return isLoggedIn;
  }, [isLoggedIn]);

  return (
    <PrivateRoute isAllowed={isAllowed} redirectTo="/login">
      {children}
    </PrivateRoute>
  );
};
