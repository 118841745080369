import React from 'react';
import { Layout } from '../../layout/Layout.js';
import { AccountsTable } from './AccountsTable.js';

/**
 * Shows the accounts list page.
 */
export const AccountsPage = () => {
  return (
    <Layout title="Accounts">
      <h2>Accounts</h2>
      <AccountsTable />
    </Layout>
  );
};
