import { useParameters } from './useParameters.js';

/**
 * Helper hook to access the given parameter value. Throws
 * error when the route parameter does not exist.
 */
export const useRequiredParameter = (name: string) => {
  const parameters = useParameters();

  if (Object.hasOwn(parameters, name)) {
    return parameters[name] as string;
  } else {
    throw new Error(`Parameter ${name} is not in the current URL.`);
  }
};
