import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import {
  getEntry,
  getEntryFiles,
  removeEntry,
  removeEntryFile,
  uploadEntryFiles
} from '../../../../api/accounting.js';
import { Entry } from '../../../../../organizer-datamodel/accounting/Entry.js';
import { EntityFile } from '../../../../../organizer-datamodel/upload/EntityFile.js';
import { useHref } from '../../../../../packages/router/href/HrefProvider.js';

type ViewState = {
  entry: Entry;
  files: EntityFile[];
  remove: () => void;
  removeFile: (fileId: string) => void;
  uploadFiles: (files: FileList) => void;
  isUploading: boolean;
  uploadError?: string;
};

const ViewContext = createContext({} as ViewState);

type Props = {
  entryId: string;
  children: ReactNode;
};

/**
 * Entry view state provider.
 */
export const ViewProvider = ({ entryId, children }: Props) => {
  const { setHref } = useHref();
  const [entry, setEntry] = useState<Entry>();
  const [files, setFiles] = useState<EntityFile[]>([]);

  const [isUploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>();

  const updateEntry = useCallback(async () => {
    setEntry(await getEntry(entryId));
    setFiles(await getEntryFiles(entryId));
  }, [entryId]);

  const uploadFiles = useCallback(
    async (files: FileList) => {
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append('files', files[i]);
      }
      setUploadError('');
      setUploading(true);
      try {
        await uploadEntryFiles(entryId, data);
      } catch (err) {
        setUploadError(err + '');
      } finally {
        setUploading(false);
        updateEntry();
      }
    },
    [entryId, updateEntry]
  );

  const removeFile = useCallback(
    async (fileId: string) => {
      if (confirm('Remove the entry file?')) {
        try {
          await removeEntryFile(entryId, fileId);
        } catch (err) {
          setUploadError(err + '');
        } finally {
          updateEntry();
        }
      }
    },
    [entryId, updateEntry]
  );

  const remove = useCallback(async () => {
    if (confirm('Remove the entry?')) {
      try {
        await removeEntry(entryId);
      } finally {
        setHref('/entries');
      }
    }
  }, [entryId, setHref]);

  useEffect(() => {
    updateEntry();
  }, [updateEntry]);

  if (entry === undefined) {
    return null;
  }

  const value = { entry, files, removeFile, remove, uploadFiles, isUploading, uploadError };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

/**
 * Helper hook to access the entry view state.
 */
export const useEntryView = () => useContext(ViewContext);
