import React from 'react';
import { formatAmount } from '../money.js';

type Props = {
  cents: number;
};

/**
 * Renders amount in cents as normal monetary value.
 */
export const FormattedAmount = ({ cents }: Props) => {
  return <>{formatAmount(cents)}</>;
};
