import React from 'react';
import { ReactNode } from 'react';

type Props = {
  path: string;
  children: ReactNode;
};

/**
 * Helper component to specify one route. Rendering it
 * will render its children.
 */
export const Route = ({ children }: Props) => {
  return <>{children}</>;
};
