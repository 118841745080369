import React from 'react';
import { Layout } from '../layout/Layout.js';
import { Link } from '../../../packages/router/Link.js';

/**
 * Soft 404 page. Simply tells user to go to the front page.
 */
export const NotFoundPage = () => {
  return (
    <Layout title="Not Found">
      The requested page was not found. Please visit the <Link to="/">front page</Link>.
    </Layout>
  );
};
