import React from 'react';
import { AccountingTable } from '../../../AccountingTable.js';
import { FileRow } from './FileRow.js';
import { useEntryView } from './ViewProvider.js';

/**
 * Renders the uploaded files table on an entry.
 */
export const FilesTable = () => {
  const { files } = useEntryView();

  return (
    <AccountingTable>
      <thead>
        <tr>
          <th>File</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        {files.map((file) => (
          <FileRow key={file.fileId} file={file} />
        ))}
      </tbody>
    </AccountingTable>
  );
};
