import { Parameters } from '../Parameters.js';
import { RouteSpec } from './RouteSpec.js';
import { Token, parsePath } from './parsePath.js';
import { ReactNode } from 'react';

export type MatchedRoute = {
  /**
   * Path parameter values for the route.
   */
  parameters: Parameters;
  /**
   * React node for the route.
   */
  node: ReactNode;
};

/**
 * Finds the matching route and the parsed parameters.
 * If there is no matching route then this function
 * returns null.
 */
export const findRoute = (path: string, routes: RouteSpec[]): MatchedRoute | null => {
  const parsedPath = parsePath(path);
  for (const route of routes) {
    const matchedRoute = matchRoute(parsedPath, route);
    if (matchedRoute) {
      return matchedRoute;
    }
  }
  return null;
};

/**
 * Tries to extract the parameter values given the current location path
 * and a potential matching route.
 */
const matchRoute = (path: Token[], route: RouteSpec): MatchedRoute | null => {
  const parsedRoutePath = parsePath(route.path);
  if (path.length === parsedRoutePath.length) {
    const parameters: { [key: string]: string } = {};
    for (let i = 0; i < path.length; i++) {
      if (parsedRoutePath[i].type === 'directory') {
        if (parsedRoutePath[i].name !== path[i].name) {
          // Directory tokens do not match.
          return null;
        }
      } else {
        // Route path /:something has value for :something.
        parameters[parsedRoutePath[i].name] = path[i].name;
      }
    }
    return { parameters, node: route.node };
  }
  return null;
};
