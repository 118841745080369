import { clsx } from 'clsx';
import React from 'react';
import { ReactNode } from 'react';
import { FontSize } from '../FontSize.js';

type Props = {
  fontSize?: FontSize;
  fullWidth?: boolean;
  tableLayout?: 'auto' | 'fixed';
  children: ReactNode;
};

/**
 * Renders the <table> element.
 */
export const Table = ({ fontSize, fullWidth, tableLayout, children }: Props) => {
  const className = clsx({
    'text-body': fontSize === 'body',
    'text-small': fontSize === 'small',
    'text-tiny': fontSize === 'tiny'
  });
  return (
    <table className={className} style={{ width: fullWidth ? '100%' : 'auto', tableLayout }}>
      {children}
    </table>
  );
};
