import { accounts } from '../../../../../../organizer-datamodel/accounting/Chart.js';
import { DebitOrCredit } from '../../../../../../organizer-datamodel/accounting/DebitOrCredit.js';
import { Account } from '../../../../../../organizer-datamodel/accounting/Account.js';
import { creditEffect, debitEffect } from '../../../../../../organizer-datamodel/accounting/accountEffect.js';
import { SelectOption } from '../../../../../../packages/ui/form/select/SelectOption.js';

/**
 * Constructs select options for debit account selector.
 */
const createDebitOptions = () => {
  const options = accounts.map((account) => createAccountOption(account, 'debit'));
  options.unshift(emptyOption);
  return options;
};

/**
 * Constructs select options for credit account selector.
 */
const createCreditOptions = () => {
  const options = accounts.map((account) => createAccountOption(account, 'credit'));
  options.unshift(emptyOption);
  return options;
};

const createAccountOption = (account: Account, forType: DebitOrCredit): SelectOption => {
  const effect = forType === 'credit' ? creditEffect(account.type) : debitEffect(account.type);
  return {
    value: account.id,
    label: `${account.code} (${account.name}) ${effect < 0 ? '-' : '+'}`
  };
};

const emptyOption: SelectOption = { value: '', label: 'Select account' };

export const debitAccountOptions = createDebitOptions();
export const creditAccountOptions = createCreditOptions();
