import React, { useCallback } from 'react';
import { Layout } from '../../layout/Layout.js';
import { useAuth } from '../../provider/AuthProvider.js';
import { login } from '../../../api/auth.js';
import { useForm } from '../../../../packages/form/useForm.js';
import { createWriteProperty } from '../../../../packages/optics/optics/property/createWriteProperty.js';
import { useHref } from '../../../../packages/router/href/HrefProvider.js';
import { Form } from '../../../../packages/ui/form/Form.js';
import { FormRow } from '../../../../packages/ui/form/FormRow.js';
import { Input } from '../../../../packages/ui/form/Input.js';
import { Buttons } from '../../../../packages/ui/form/Buttons.js';
import { Button } from '../../../../packages/ui/button/Button.js';

/**
 * Contains login form errors.
 */
export type Errors = {
  username?: string;
  password?: string;
};

/**
 * Contains input values for the login form.
 */
export type Values = {
  username: string;
  password: string;
};

const initialValues: Values = {
  username: '',
  password: ''
};

/**
 * Login page to log in with username and password.
 */
export const LoginPage = () => {
  const { setHref } = useHref();
  const { setLoggedIn } = useAuth();

  const submit = useCallback(
    async (values: Values) => {
      await login(values.username, values.password);
      setLoggedIn(true);
      setHref('/');
    },
    [setHref, setLoggedIn]
  );

  const handleSubmissionError = useCallback(() => {
    return {
      username: 'Invalid username or password.',
      password: 'Invalid username or password.'
    };
  }, []);

  const { handleSubmit, values, setValues, errors } = useForm<Values, Errors>({
    submit,
    initialValues,
    initialErrors: {},
    validate: validateLogin,
    handleSubmissionError
  });

  const handleUsernameChange = createWriteProperty(setValues, 'username');
  const handlePasswordChange = createWriteProperty(setValues, 'password');

  return (
    <Layout title="Login">
      <div className="login-form">
        <Form onSubmit={handleSubmit}>
          <h1>Log in</h1>
          <FormRow columns={1}>
            <Input
              label="Username"
              name="username"
              value={values.username}
              onChange={handleUsernameChange}
              error={errors.username}
            />
          </FormRow>
          <FormRow columns={1}>
            <Input
              type="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handlePasswordChange}
              error={errors.password}
            />
          </FormRow>
          <FormRow columns={1}>
            <Buttons>
              <Button type="submit">Logi sisse</Button>
            </Buttons>
          </FormRow>
        </Form>
      </div>
    </Layout>
  );
};

/**
 * Validates the login form values.
 */
const validateLogin = async (values: Values): Promise<Errors> => {
  const errors: Errors = {};
  if (values.username === '') {
    errors.username = 'Please enter username.';
  }
  if (values.password === '') {
    errors.password = 'Please enter password.';
  }
  return errors;
};
