import {
  createObjectValidator,
  numberValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';

/**
 * Summarises the comments under one post.
 */
export type PostSummary = {
  /**
   * Slug of the post.
   */
  slug: string;
  /**
   * Title of the post.
   */
  title: string;
  /**
   * ID of the post entry.
   */
  postId: string;
  /**
   * Count of the comments.
   */
  commentCount: number;
  /**
   * Unix timestamp of the last comment.
   */
  lastCommentDate: number;
};

export const validatorOfPostSummary = createObjectValidator<PostSummary>('PostSummary', {
  slug: stringValidator,
  title: stringValidator,
  postId: uuidV4Validator,
  commentCount: numberValidator,
  lastCommentDate: numberValidator
});
