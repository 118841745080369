import { useLayoutEffect } from 'react';

/**
 * Sets the page favicon.
 */
export const useSetFavicon = (path: string) => {
  useLayoutEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = path;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, [path]);
};
