import {
  createObjectValidator,
  numberValidator,
  stringValidator,
  uuidV4Validator
} from '../../packages/validator/validator.js';

/**
 * Contains data for showing in the entries table.
 */
export type EntrySummary = {
  id: string;
  date: string;
  title: string;
  /**
   * Number of files on this entry.
   */
  fileCount: number;
  /**
   * Sum of incomes under this entry.
   */
  totalIncome: number;
  /**
   * Sum of expenses under this entry.
   */
  totalExpense: number;
  /**
   * Total change in assets under this entry.
   */
  changeAssets: number;
  /**
   * Total change in liabilities under this entry.
   */
  changeLiabilities: number;
  /**
   * Total change in cash under this entry.
   */
  changeCash: number;
};

export const validatorOfEntrySummary = createObjectValidator<EntrySummary>('EntrySummary', {
  id: uuidV4Validator,
  date: stringValidator,
  title: stringValidator,
  fileCount: numberValidator,
  totalIncome: numberValidator,
  totalExpense: numberValidator,
  changeAssets: numberValidator,
  changeLiabilities: numberValidator,
  changeCash: numberValidator
});
