import { EntryValues } from '../../../../../../organizer-datamodel/accounting/form/EntryValues.js';

/**
 * Links item dates and amounts.
 */
export type LinkMap = {
  /**
   * Links date fields together.
   */
  dates: Record<string, string>;
  /**
   * Links amount fields together.
   */
  amounts: Record<string, string>;
};

/**
 * Creates linkage map between the items in a copied entry.
 */
export const createLinkMap = (entry: EntryValues): LinkMap => {
  const map: LinkMap = { dates: {}, amounts: {} };
  const { items } = entry;
  for (let i = 0; i < items.length; i++) {
    for (let j = i + 1; j < items.length; j++) {
      const item = items[i];
      const linked = items[j];
      if (item.date === linked.date) {
        map.dates[item.id] = linked.id;
      }
      if (item.amount === linked.amount && item.currency === linked.currency && item.currency === 'eur') {
        map.amounts[item.id] = linked.id;
      }
    }
  }
  return map;
};

/**
 * Checks whether the given item is linked to some previous item by the date value.
 */
export const isDateLinkedTo = (itemId: string, linkMap: LinkMap) => {
  return Object.values(linkMap.dates).includes(itemId);
};

/**
 * Checks whether the given item is linked to some previous item by the amount value.
 */
export const isAmountLinkedTo = (itemId: string, linkMap: LinkMap) => {
  return Object.values(linkMap.amounts).includes(itemId);
};

/**
 * Removes date link to this item. This means that the item can be updated
 * now independently from the previous entry.
 */
export const removeDateLink = (itemId: string, linkMap: LinkMap): LinkMap => {
  return {
    ...linkMap,
    dates: Object.fromEntries(Object.entries(linkMap.dates).filter((entry) => entry[1] !== itemId))
  };
};

/**
 * Removes amount link to this item. This means that the item can be updated
 * now independently from the previous entry.
 */
export const removeAmountLink = (itemId: string, linkMap: LinkMap): LinkMap => {
  return {
    ...linkMap,
    amounts: Object.fromEntries(Object.entries(linkMap.amounts).filter((entry) => entry[1] !== itemId))
  };
};
