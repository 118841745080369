import React from 'react';
import { CopyLink } from '../../links/entry/CopyLink.js';
import { EditLink } from '../../links/entry/EditLink.js';
import { useEntryView } from './ViewProvider.js';
import { Buttons } from '../../../../../packages/ui/form/Buttons.js';
import { Button } from '../../../../../packages/ui/button/Button.js';

/**
 * Renders the buttons under the entry view.
 */
export const ViewButtons = () => {
  const { entry, remove } = useEntryView();

  return (
    <Buttons>
      <EditLink entryId={entry.id} />
      <CopyLink entryId={entry.id} />
      <Button onClick={remove}>Remove</Button>
    </Buttons>
  );
};
