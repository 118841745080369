import React from 'react';
import { EntryRow } from './EntryRow.js';
import { MonthEntries } from '../../../../organizer-datamodel/accounting/Entry.js';

type Props = {
  month: MonthEntries;
};

/**
 * Renders rows corresponding to month in the entries table.
 */
export const MonthRows = ({ month }: Props) => {
  return (
    <>
      <tr>
        <th colSpan={8}>{month.monthName}</th>
      </tr>
      {month.entries.map((entry) => (
        <EntryRow key={entry.id} entry={entry} />
      ))}
    </>
  );
};
