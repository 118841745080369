import React, { useMemo, ReactElement, ReactNode } from 'react';
import { MatchedRoute, findRoute } from '../route/findRoute.js';
import { RouteProvider } from '../RouteProvider.js';
import { useHref } from '../href/HrefProvider.js';
import { RouteSpec } from '../route/RouteSpec.js';

type RouteProps = {
  path: string;
  children: ReactNode;
};

type Props = {
  children: ReactElement<RouteProps> | ReactElement<RouteProps>[];
  fallback: ReactNode;
};

/**
 * The main component of the router.
 */
export const Router = ({ children, fallback }: Props) => {
  const { href } = useHref();

  const matchingRoute = useMemo<MatchedRoute>(() => {
    const path = extractPath(href);
    const routes = extractRoutes(children);
    const route = findRoute(path, routes);
    if (route) {
      return route;
    } else {
      return { parameters: {}, node: fallback };
    }
  }, [href, fallback, children]);

  return <RouteProvider parameters={matchingRoute.parameters}>{matchingRoute.node}</RouteProvider>;
};

/**
 * Extracts route spec array from the children.
 */
const extractRoutes = (children: ReactElement<RouteProps> | ReactElement<RouteProps>[]): RouteSpec[] => {
  if (Array.isArray(children)) {
    return children.map((child) => {
      return {
        path: child.props.path,
        node: child.props.children
      };
    });
  } else {
    return [
      {
        path: children.props.path,
        node: children.props.children
      }
    ];
  }
};

/**
 * Extracts path from the given hyperlink.
 */
const extractPath = (href: string) => {
  if (href.startsWith('/')) {
    const url = new URL(`http://localhost${href}`);
    return url.pathname;
  } else {
    const url = new URL(href);
    return url.pathname;
  }
};
