import React, { ReactNode, createContext, useContext } from 'react';

type Value = {
  href: string;
  setHref: (href: string) => void;
};

const Context = createContext<Value | undefined>(undefined);

type Props = {
  href: string;
  setHref: (href: string) => void;
  children: ReactNode;
};

/**
 * Helper provider to pass href to the router.
 */
export const HrefProvider = ({ href, setHref, children }: Props) => {
  return <Context.Provider value={{ href, setHref }}>{children}</Context.Provider>;
};

/**
 * Returns the provided href.
 */
export const useHref = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('HrefProvider is not there.');
  }
  return context;
};
