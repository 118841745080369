import React from 'react';
import { AccountLink } from '../links/AccountLink.js';
import { Account } from '../../../../organizer-datamodel/accounting/Account.js';

type Props = {
  account: Account;
};

/**
 * Renders one row in the accounts table.
 */
export const AccountRow = ({ account }: Props) => {
  return (
    <tr>
      <td>
        <AccountLink accountId={account.id} />
      </td>
      <td>{account.name}</td>
      <td>{account.type}</td>
    </tr>
  );
};
