import React, { ReactNode, Fragment, useEffect } from 'react';
import { useSetFavicon } from '../hooks/useSetFavicon.js';
import { useAuth } from '../provider/AuthProvider.js';
import { TopMenu } from './TopMenu.js';
import { Container } from '../../../packages/ui/Container.js';

type Props = {
  children?: ReactNode;
  favicon?: string;
  title: string;
};

/**
 * Renders the main page layout.
 */
export const Layout = ({ children, favicon = 'favicon.ico', title }: Props) => {
  const { isLoggedIn } = useAuth();

  useSetFavicon(`/v-${VERSION}/${favicon}`);

  useEffect(() => {
    document.title = `${title} - Organizer`;
  }, [title]);

  return (
    <Fragment>
      <div id="header">
        <strong>ORGANIZER</strong>
        {isLoggedIn && <TopMenu />}
      </div>
      <Container width="fixed-wide">{children}</Container>
    </Fragment>
  );
};
