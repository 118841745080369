import {
  createObjectValidator,
  integerValidator,
  stringValidator
} from '../../packages/validator/validator.js';
import { creditEffect, debitEffect } from './accountEffect.js';
import {
  isAssetAccountType,
  isCashAccountType,
  isExpenseAccountType,
  isIncomeAccountType,
  isLiabilityAccountType
} from './AccountType.js';
import { findAccount, validatorOfAccountId } from './Chart.js';
import { Currency, validatorOfCurrency } from './Currency.js';

/**
 * One item of an entry.
 */
export type Item = {
  /**
   * Title of the item.
   */
  title: string;
  /**
   * Date of the item in YYYY-MM-DD pattern.
   */
  date: string;
  /**
   * Debit side account ID.
   */
  debit: string;
  /**
   * Credit side account ID.
   */
  credit: string;
  /**
   * Amount of the item in cents.
   */
  amount: number;
  /**
   * Eur amount of the item in cents.
   */
  eurAmount: number;
  /**
   * Currency (eur, usd, gbp)
   */
  currency: Currency;
};

export const validatorOfItem = createObjectValidator<Item>('Item', {
  title: stringValidator,
  date: stringValidator,
  debit: validatorOfAccountId,
  credit: validatorOfAccountId,
  amount: integerValidator,
  eurAmount: integerValidator,
  currency: validatorOfCurrency
});

/**
 * Returns income effect of this item.
 */
export const getIncomeEffect = (item: Item) => {
  let incomeEffect = 0;
  const debitAccount = findAccount(item.debit);
  const creditAccount = findAccount(item.credit);
  if (isIncomeAccountType(debitAccount.type)) {
    incomeEffect += debitEffect(debitAccount.type) * item.amount;
  }
  if (isIncomeAccountType(creditAccount.type)) {
    incomeEffect += creditEffect(creditAccount.type) * item.amount;
  }
  return incomeEffect;
};

/**
 * Returns expense effect of this item.
 */
export const getExpenseEffect = (item: Item) => {
  let expenseEffect = 0;
  const debitAccount = findAccount(item.debit);
  const creditAccount = findAccount(item.credit);
  if (isExpenseAccountType(debitAccount.type)) {
    expenseEffect += debitEffect(debitAccount.type) * item.amount;
  }
  if (isExpenseAccountType(creditAccount.type)) {
    expenseEffect += creditEffect(creditAccount.type) * item.amount;
  }
  return expenseEffect;
};

/**
 * Returns asset effect of this item.
 */
export const getAssetEffect = (item: Item) => {
  let assetEffect = 0;
  const debitAccount = findAccount(item.debit);
  const creditAccount = findAccount(item.credit);
  if (isAssetAccountType(debitAccount.type)) {
    assetEffect += debitEffect(debitAccount.type) * item.amount;
  }
  if (isAssetAccountType(creditAccount.type)) {
    assetEffect += creditEffect(creditAccount.type) * item.amount;
  }
  return assetEffect;
};

/**
 * Returns liability effect of this item.
 */
export const getLiabilityEffect = (item: Item) => {
  let liabilityEffect = 0;
  const debitAccount = findAccount(item.debit);
  const creditAccount = findAccount(item.credit);
  if (isLiabilityAccountType(debitAccount.type)) {
    liabilityEffect += debitEffect(debitAccount.type) * item.amount;
  }
  if (isLiabilityAccountType(creditAccount.type)) {
    liabilityEffect += creditEffect(creditAccount.type) * item.amount;
  }
  return liabilityEffect;
};

/**
 * Returns cash effect of this item.
 */
export const getCashEffect = (item: Item) => {
  let cashEffect = 0;
  const debitAccount = findAccount(item.debit);
  const creditAccount = findAccount(item.credit);
  if (isCashAccountType(debitAccount.type)) {
    cashEffect += debitEffect(debitAccount.type) * item.amount;
  }
  if (isCashAccountType(creditAccount.type)) {
    cashEffect += creditEffect(creditAccount.type) * item.amount;
  }
  return cashEffect;
};
