import { formatAmount } from '../../../../money.js';
import { ItemErrors } from '../entry/EntryErrors.js';
import * as uuid from 'uuid';
import { ItemValues } from '../../../../../../organizer-datamodel/accounting/form/ItemValues.js';
import { Item } from '../../../../../../organizer-datamodel/accounting/Item.js';
import { Currency } from '../../../../../../organizer-datamodel/accounting/Currency.js';
import { validateIsoDate } from '../../../../../../packages/date/dateFunctions.js';

/**
 * Creates item values object.
 */
export const createItemValues = (item?: Item): ItemValues => {
  const values: ItemValues = {
    title: '',
    date: '',
    debit: '',
    credit: '',
    currency: 'eur',
    amount: '',
    eurAmount: '',
    id: uuid.v4()
  };

  return item ? setItemValues(item, values) : values;
};

/**
 * Helper function to set item values from an exiting item.
 */
const setItemValues = (item: Item, values: ItemValues) => ({
  ...values,
  title: item.title,
  date: item.date,
  debit: item.debit,
  credit: item.credit,
  currency: item.currency,
  amount: formatAmount(item.amount),
  eurAmount: formatAmount(item.amount)
});

/**
 * Converts values back to Item.
 */
export const convertToItem = (values: ItemValues): Item => {
  const date = validateIsoDate(values.date);
  if (!date) {
    throw new Error(`Invalid item date: ${date}.`);
  }
  return {
    date: date,
    title: values.title,
    debit: values.debit,
    credit: values.credit,
    amount: Math.round(parseFloat(values.amount) * 100),
    eurAmount: Math.round(parseFloat(values.eurAmount) * 100),
    currency: values.currency as Currency
  };
};

/**
 * Helper function to validate one item.
 */
export const validateItem = (values: ItemValues): ItemErrors => {
  const errors: ItemErrors = {};

  if (values.title.trim() === '') {
    errors.title = 'Please enter the item title.';
  }

  if (values.date.trim() === '') {
    errors.date = 'Please enter the item date.';
  }
  const match = values.date.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) {
    errors.date = 'Please enter the item date in correct format.';
  }

  if (values.debit === '') {
    errors.debit = 'Please select an account.';
  }

  if (values.credit === '') {
    errors.credit = 'Please select an account.';
  }

  if (values.currency === '') {
    errors.currency = 'Please select a currency.';
  }

  if (values.amount.trim() === '') {
    errors.amount = 'Please enter the item amount.';
  } else if (Number.isNaN(parseFloat(values.amount))) {
    errors.amount = 'Please enter the item amount as a number.';
  }

  if (values.amount.trim() === '') {
    errors.eurAmount = 'Please enter the item amount.';
  } else if (Number.isNaN(parseFloat(values.eurAmount))) {
    errors.eurAmount = 'Please enter the item amount as a number.';
  }

  return errors;
};
