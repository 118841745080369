import { useEffect } from 'react';
import { useHref } from './HrefProvider.js';

type Props = {
  to: string | URL;
};

/**
 * Helper component to implement redirects.
 */
export const Redirect = ({ to }: Props) => {
  const { setHref } = useHref();

  useEffect(() => {
    setHref(to.toString());
  }, [setHref, to]);

  return null;
};
