import React from 'react';
import { Layout } from '../../../layout/Layout.js';
import { ViewProvider } from './ViewProvider.js';
import { EntryView } from './EntryView.js';
import { useRequiredParameter } from '../../../../../packages/router/params/useRequiredParameter.js';

/**
 * Shows the entry page.
 */
export const EntryPage = () => {
  const entryId = useRequiredParameter('entryId');

  return (
    <Layout title="Accounting entry">
      <ViewProvider entryId={entryId}>
        <EntryView />
      </ViewProvider>
    </Layout>
  );
};
