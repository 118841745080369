import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

/**
 * The filter store state type.
 */
export type FilterState = {
  year: string;
  month: string;
  titleSearch: string;
  setPeriod: (year: string, month: string) => void;
  setTitleSearch: (search: string) => void;
};

const currentDate = new Date();

/**
 * The period store context.
 */
const FilterContext = createContext({} as FilterState);

/**
 * The period store provider.
 */
export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [year, setYear] = useState(() => currentDate.getUTCFullYear().toString());
  const [month, setMonth] = useState(() => (currentDate.getUTCMonth() + 1).toString().padStart(2, '0'));

  const [titleSearch, setTitleSearch] = useState('');

  const setPeriod = useCallback((year: string, month: string) => {
    setYear(year);
    setMonth(month);
  }, []);

  const value = { year, month, titleSearch, setPeriod, setTitleSearch };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

/**
 * Hook to access the filter state.
 */
export const useFilter = () => useContext(FilterContext);
