import React, { MouseEvent, ReactNode, forwardRef, useCallback } from 'react';
import { useHref } from './href/HrefProvider.js';

type Props = {
  to: string;
  children: ReactNode;
  target?: string;
  title?: string;
  className?: string;
};

/**
 * Helper component to implement in-router links. Renders as anchor element.
 */
export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ to, children, target, title, className }, ref) => {
    const { setHref } = useHref();

    const handleClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        setHref(to);
      },
      [setHref, to]
    );

    return (
      <a ref={ref} href="#" title={title} target={target} onClick={handleClick} className={className}>
        {children}
      </a>
    );
  }
);

Link.displayName = 'Link';
