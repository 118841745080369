import React from 'react';
import { Link } from '../../../../../packages/router/Link.js';
import { euc } from '../../../../../packages/url/encodeUriComponents.js';

type Props = {
  entryId: string;
};

/**
 * Renders the entry edit link.
 */
export const EditLink = ({ entryId }: Props) => {
  return (
    <Link to={euc`/edit/${entryId}`} className="button print-none">
      Edit
    </Link>
  );
};
