import { ReactNode, useCallback } from 'react';
import { useHrefPolling } from './useHrefPolling.js';
import { HrefProvider } from '../HrefProvider.js';
import React from 'react';
import { useHistory } from './useHistory.js';

type Props = {
  children: ReactNode;
};

/**
 * Browser-specific HrefProvider. Polls window.location.
 */
export const BrowserHrefProvider = ({ children }: Props) => {
  const { href, setHref } = useHrefPolling();
  const { push } = useHistory();

  const handleSetRef = useCallback(
    (url: string) => {
      push(url);
      setHref(url);
    },
    [setHref, push]
  );

  return (
    <HrefProvider href={href} setHref={handleSetRef}>
      {children}
    </HrefProvider>
  );
};
