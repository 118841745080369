import React, { useEffect, useState } from 'react';
import { Layout } from '../../organizer-frontend/accounting/layout/Layout.js';
import { FixedTable } from '../../packages/ui/table/quick/FixedTable.js';
import { Column } from '../../packages/ui/table/quick/Column.js';
import { getPathRecords } from '../../frontend-api/analytics.js';
import { PathRecord } from '../../model/analytics/PathRecord.js';
import { useRequiredParameter } from '../../packages/router/params/useRequiredParameter.js';

/**
 * Shows analytics for the given file.
 */
export const AnalyticsFilePage = () => {
  const [records, setRecords] = useState<PathRecord[]>([]);

  const file = useRequiredParameter('file');

  useEffect(() => {
    (async () => {
      setRecords(await getPathRecords(file));
    })();
  }, [file]);

  const columns: Column<PathRecord>[] = [
    {
      span: 3,
      title: 'Path',
      render: (row: PathRecord) => row.path
    },
    {
      span: 4,
      title: 'Title',
      render: (row: PathRecord) => row.title
    },
    {
      span: 1,
      title: 'Unique visitor count',
      render: (row: PathRecord) => row.count,
      textAlign: 'right'
    }
  ];

  return (
    <Layout title="Analytics">
      <h2>Analytics - {file}</h2>
      <FixedTable columns={columns} rows={records} fontSize="tiny" />
    </Layout>
  );
};
