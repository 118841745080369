import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from '../../layout/Layout.js';
import { FilterForm } from '../../FilterForm.js';
import { AccountingTable } from '../../AccountingTable.js';
import { getEntries } from '../../../api/accounting.js';
import { useFilter } from '../../provider/FilterProvider.js';
import { MonthRows } from './MonthEntries.js';
import { NewButton } from '../links/entry/NewButton.js';
import { EntrySummary } from '../../../../organizer-datamodel/accounting/EntrySummary.js';
import { groupByMonth } from '../../../../organizer-datamodel/accounting/Entry.js';

/**
 * Shows the entries list page.
 */
export const EntriesPage = () => {
  const { year, month, titleSearch } = useFilter();

  const [entries, setEntries] = useState<EntrySummary[]>([]);

  const filteredEntries = useMemo(() => {
    const titleSearchLowerCase = titleSearch.toLowerCase();
    return entries.filter((entry) => entry.title.toLowerCase().includes(titleSearchLowerCase));
  }, [entries, titleSearch]);

  const months = useMemo(() => {
    return groupByMonth(filteredEntries);
  }, [filteredEntries]);

  const updateEntries = useCallback(async () => {
    setEntries(await getEntries(year, month));
  }, [year, month]);

  useEffect(() => {
    updateEntries();
  }, [updateEntries]);

  return (
    <Layout title="Accounting entries">
      <h2>Entries</h2>
      <FilterForm />
      <NewButton />
      <AccountingTable>
        <thead>
          <tr>
            <th>Date</th>
            <th className="text-right">Files</th>
            <th className="text-right">Income</th>
            <th className="text-right">Expense</th>
            <th className="text-right">Assets</th>
            <th className="text-right">Liabilities</th>
            <th className="text-right">Cash</th>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <MonthRows key={month.monthName} month={month} />
          ))}
        </tbody>
      </AccountingTable>
    </Layout>
  );
};
