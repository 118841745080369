import React from 'react';
import { AccountingTable } from '../../AccountingTable.js';
import { AccountRow } from './AccountRow.js';
import { accounts } from '../../../../organizer-datamodel/accounting/Chart.js';

/**
 * Renders the table with all accounts in the system.
 */
export const AccountsTable = () => {
  return (
    <AccountingTable>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {accounts.map((account) => (
          <AccountRow key={account.id} account={account} />
        ))}
      </tbody>
    </AccountingTable>
  );
};
