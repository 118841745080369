import { clsx } from 'clsx';
import React, { memo } from 'react';
import { SelectOption } from './SelectOption.js';
import { useId } from '../../useId.js';
import { InputWrapper } from '../InputWrapper.js';
import { useTargetValue } from '../useTargetValue.js';

type Props = {
  label?: string;
  value?: string;
  error?: string;
  onChange?: (value: string) => void;
  options: SelectOption[];
  initialValue?: string;
};

/**
 * Renders <select> element.
 */
export const Select = memo(function Select({ label, value, error, onChange, options, initialValue }: Props) {
  const labelClass = clsx({ 'error': error });
  const inputClass = clsx({ 'error': error });
  const helpClass = clsx('help', { 'error': error });

  const id = useId();
  const handleChange = useTargetValue(onChange);

  return (
    <InputWrapper>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      <select
        className={inputClass}
        value={value}
        onChange={handleChange}
        defaultValue={initialValue}
        id={id}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <div className={helpClass}>{error}</div>}
    </InputWrapper>
  );
});
