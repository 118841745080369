/**
 * A normal path token (directory). For example, "user" in /user/:id.
 */
export type Directory = {
  type: 'directory';
  name: string;
};

/**
 * A parameter inside the path. For example, "id" in /user/:id.
 */
export type Parameter = {
  type: 'parameter';
  name: string;
};

/**
 * A path token is either a directory or a parameter.
 */
export type Token = Directory | Parameter;

/**
 * Parses given path into an array of tokens.
 */
export const parsePath = (path: string): Token[] => {
  if (path === '') {
    return [];
  }
  if (path === '/') {
    return [];
  }
  if (!path.startsWith('/')) {
    throw new Error('Route paths must start with /');
  }
  if (path.endsWith('/')) {
    return parsePath(path.substring(0, path.length - 1));
  }

  const tokens = path.split('/');
  tokens.shift();
  return tokens.map((token) => parseToken(token));
};

const parseToken = (token: string): Token => {
  if (token.startsWith(':')) {
    return { type: 'parameter', name: token.substring(1) };
  } else {
    return { type: 'directory', name: token };
  }
};
