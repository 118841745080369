import { useEffect, useRef, useState } from 'react';

type PollingReturn = {
  /**
   * Current URL.
   */
  href: string;
  /**
   * Fast-lane option for Link components.
   */
  setHref: (url: string) => void;
};

/**
 * Helper hook to encapsulate polling of current location.
 */
export const useHrefPolling = (): PollingReturn => {
  const [href, setHref] = useState<string>(window.location.href);
  const intervalRef = useRef<number>();

  useEffect(() => {
    // Sets up a polling times.
    intervalRef.current = setInterval(() => {
      setHref(window.location.href);
    }, 100) as unknown as number;

    return () => {
      // Clears the polling watcher.
      clearInterval(intervalRef.current);
    };
  }, []);

  return { href, setHref };
};
