import React, { useMemo } from 'react';
import { formatAmount } from '../../../money.js';
import { AccountingTable } from '../../../AccountingTable.js';
import { EntryLike, calculateChanges } from '../../../../../organizer-datamodel/accounting/ChangeMap.js';
import { Link } from '../../../../../packages/router/Link.js';

type Props = {
  entry: EntryLike;
};

/**
 * Renders the block of changes under an entry.
 */
export const ChangesTable = ({ entry }: Props) => {
  const changes = useMemo(() => calculateChanges(entry).asArray(), [entry]);

  return (
    <AccountingTable>
      <thead>
        <tr>
          <th>Account</th>
          <th className="text-right">Change (EUR)</th>
        </tr>
      </thead>
      <tbody>
        {changes.map((item) => (
          <tr key={item.account.id}>
            <td>
              <Link to={`/account/${item.account.id}`}>
                {item.account.code} ({item.account.name})
              </Link>
            </td>
            <td className="text-right">{formatAmount(item.change)}</td>
          </tr>
        ))}
      </tbody>
    </AccountingTable>
  );
};
