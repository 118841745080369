import React from 'react';
import { Link } from '../../../../../packages/router/Link.js';

/**
 * Renders the new entry button.
 */
export const NewButton = () => {
  return (
    <Link to="/new" className="button print-none">
      New entry
    </Link>
  );
};
