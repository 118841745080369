import React, { MouseEvent, useCallback } from 'react';
import { logout } from '../../api/auth.js';
import { useAuth } from '../provider/AuthProvider.js';
import { useHref } from '../../../packages/router/href/HrefProvider.js';

/**
 * Renders the application logout link.
 */
export const LogoutLink = () => {
  const { setHref } = useHref();

  const { setLoggedIn } = useAuth();

  const handleLogout = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();
      await logout();
      setLoggedIn(false);
      setHref('/login');
    },
    [setHref, setLoggedIn]
  );

  return (
    <a href="#" onClick={handleLogout}>
      Log out
    </a>
  );
};
