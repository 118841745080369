import React, { useMemo } from 'react';
import { Layout } from '../../layout/Layout.js';
import { FilterForm } from '../../FilterForm.js';
import { ViewProvider } from './ViewProvider.js';
import { AccountTitle } from './AccountTitle.js';
import { AccountTotal } from './AccountTotal.js';
import { ItemsTable } from './ItemsTable.js';
import { findAccount } from '../../../../organizer-datamodel/accounting/Chart.js';
import { useRequiredParameter } from '../../../../packages/router/params/useRequiredParameter.js';

/**
 * Shows the entries list page.
 */
export const AccountPage = () => {
  const accountId = useRequiredParameter('accountId');

  const title = useMemo(() => {
    return findAccount(accountId).name;
  }, [accountId]);

  return (
    <Layout title={title}>
      <ViewProvider accountId={accountId}>
        <AccountTitle />
        <FilterForm />
        <AccountTotal />
        <ItemsTable />
      </ViewProvider>
    </Layout>
  );
};
