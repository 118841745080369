import { PathRecord } from '../model/analytics/PathRecord.js';
import { requestJSON } from '../packages/fetcher/fetcherFunctions.js';
import { euc } from '../packages/url/encodeUriComponents.js';

/**
 * Retrieves entries.
 */
export const getAnalyticsFiles = async () => {
  return requestJSON<string[], undefined>(euc`/api/analytics/files`, 'GET', undefined);
};

/**
 * Retrieves statistics.
 */
export const getPathRecords = async (file: string) => {
  return requestJSON<PathRecord[], undefined>(euc`/api/analytics/files/${file}`, 'GET', undefined);
};
