import React from 'react';
import { currencyOptions } from './currencyOptions.js';
import { Select } from '../../../../../../packages/ui/form/select/Select.js';

type Props = {
  error?: string;
  value: string;
  onChange: (value: string) => void;
};

/**
 * Helper component to render currency selector for item forms.
 */
export const CurrencySelector = ({ error, value, onChange }: Props) => {
  return (
    <Select
      label="Currency"
      initialValue={value}
      options={currencyOptions}
      error={error}
      onChange={onChange}
    />
  );
};
