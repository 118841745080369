import { Modify } from '../Modify.js';
import { FunctionCache } from '../cache.js';

const cache = new FunctionCache();

/**
 * Creates "modify" function for the given index in the array.
 * Returns stable function identity.
 */
export const createModifyIndex = <T>(op: Modify<T>, index: number): Modify<T[]> =>
  cache.cached(op, index, () => (value: T[]) => value.map((item, i) => (i === index ? op(item) : item)));
