import { PostSummary, validatorOfPostSummary } from '../../organizer-datamodel/comments/PostSummary.js';
import { StoredComment, validatorOfStoredComment } from '../../organizer-datamodel/comments/StoredComment.js';
import { createArrayValidator } from '../../packages/validator/validator.js';
import { requestGetJson } from '../../packages/fetcher/fetcherFunctions.js';
import { euc } from '../../packages/url/encodeUriComponents.js';

/**
 * Requests post summaries from the backend.
 */
export const getPostSummaries = async () => {
  const data = await requestGetJson<PostSummary[]>(euc`/api/comments/summaries`);
  return createArrayValidator(validatorOfPostSummary)(data);
};

/**
 * Requests one post comments.
 */
export const getComments = async (postId: string) => {
  const data = await requestGetJson<StoredComment[]>(euc`/api/post/${postId}/comments`);
  return createArrayValidator(validatorOfStoredComment)(data);
};

/**
 * Requests one comment.
 */
export const getComment = async (commentId: string) => {
  const data = await requestGetJson<StoredComment[]>(euc`/api/comment/${commentId}`);
  return validatorOfStoredComment(data);
};
